import {
  IAllSubCategory,
  ICategory,
  ICreateTicketRequest,
  IFetchAttachmentResponse,
  IFetchCommentResponse,
  IFetchTicketsRequest,
  IFetchTicketsResponse, IFetchUpdateTicketRequest,
  ISubCategory
} from '@/store/modules/tickets/interfaces'
import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'

export const ticketsAPI = {
  async fetchAllTickets (dto: IFetchTicketsRequest): Promise<IFetchTicketsResponse> {
    const response: AxiosResponse = await generalServiceAPI.get<IFetchTicketsResponse>('tickets/GetAllTickets', { params: dto })
    return response.data
  },
  async fetchCategories (): Promise<ICategory[]> {
    const response: AxiosResponse<ICategory[]> = await generalServiceAPI.get('tickets/GetCategories')
    return response.data
  },
  async fetchSubCategories (id: string): Promise<ISubCategory[]> {
    const response: AxiosResponse<ISubCategory[]> = await generalServiceAPI.get(`tickets/GetSubCategoriesByCategoryId/${id}`)
    return response.data
  },
  async fetchAllSubCategories (): Promise<IAllSubCategory[]> {
    const response: AxiosResponse<IAllSubCategory[]> = await generalServiceAPI.get('tickets/GetSubCategories')
    return response.data
  },
  async fetchCreateComment (dto: Omit<IFetchUpdateTicketRequest, 'attachments'>): Promise<IFetchCommentResponse> {
    const response: AxiosResponse<IFetchCommentResponse> = await generalServiceAPI.post('tickets/CreateComment', { ...dto })
    return response.data
  },
  async fetchUploadAttachment (dto: Pick<IFetchUpdateTicketRequest, 'attachments' | 'ticketId'>): Promise<IFetchAttachmentResponse> {
    const response: AxiosResponse<IFetchAttachmentResponse> = await generalServiceAPI.post(`tickets/UploadAttachmentsByTicketId?ticketId=${dto.ticketId}`, dto.attachments)
    return response.data
  },
  async fetchAttachmentsByTicket (id: string): Promise<IFetchAttachmentResponse> {
    try {
      const response: AxiosResponse<IFetchAttachmentResponse> = await generalServiceAPI.get(`tickets/GetAttachmentsByTicketId/${id}`)
      return response.data
    } catch (err) {
      console.log(err)
      return { attachmentList: [] }
    }
  },
  async createTicket (dto: ICreateTicketRequest): Promise<any> {
    const market = 'HU'
    const response: AxiosResponse = await generalServiceAPI.post<any>(`tickets/CreateTicket?market=${market}`, dto)
    return response.data
  }
}

// user need tto be CDC user ID
