import {
  createRouter as createAsahiRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  NavigationGuardNext
} from 'vue-router'
import { Store } from 'vuex'
import { IRootState } from '@/store'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { nextTick } from 'vue'
import { constants } from '@/constants'
import { getValueByLabel } from '@/utils/permissions'

const history = createWebHistory()

export type AsahiRouter = Router & { navigate: (url: string | CustomEvent) => void }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createRoutes (store: Store<IRootState>): RouteRecordRaw[] {
  const checkPermission = (next: NavigationGuardNext, key: string) => {
    if (!(typeof (store.state?.main?.permissions) === 'object' &&
          Object.keys(store.state?.main?.permissions).length > 0)) {
      store.dispatch('main/FETCH_PERMISSIONS', { root: true })
    }
    function proceed () {
      const permissionData = store.state?.main?.permissions
      if (store.getters.getIsFullLoaded() === true &&
      store.getters['main/getIsPermissionLoaded']() === true) {
        if (store.state.auth.user.allAvailablePermission.includes(key) &&
          getValueByLabel(permissionData, key)) {
          next()
        } else {
          next({ name: 'Error403' })
        }
      }
    }

    if (store.getters.getIsFullLoaded() !== true &&
    store.getters['main/getIsPermissionLoaded']() !== true) {
      window.gigya.hasSession().then(
        window.gigya.socialize.getUserInfo({
          callback: (res: any) => {
            if (res.UID != null) {
              store.commit('SET_AUTH_USER', res)
              store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID)
            } else {
              store.dispatch('logout')
              next({ name: 'login' })
            }
          }
        })
      )

      store.watch(store.getters.getIsFullLoaded, function () {
        if (store.getters.getIsFullLoaded() === true &&
        store.getters['main/getIsPermissionLoaded']() === true) {
          proceed()
        }
      })
    } else if (store.getters['main/getIsPermissionLoaded']() !== true) {
      store.watch(store.getters['main/getIsPermissionLoaded'], function () {
        if (store.getters.getIsFullLoaded() === true &&
        store.getters['main/getIsPermissionLoaded']() === true) {
          proceed()
        }
      })
    } else {
      proceed()
    }
  }

  return [
    {
      path: '/',
      name: 'homepage',
      meta: { requireAuthentication: true, title: 'HU Portal' },
      beforeEnter: (to, from, next) => {
        return checkPermission(next, 'Home Page')
      },
      component: () => import(/* webpackChunkName: "homepage" */ '../pages/HomePage.vue')
    },
    {
      path: '/verification',
      name: 'userVerification',
      meta: { isUserVerification: true, title: 'User verification' },
      component: () => import(/* webpackChunkName: "userVerification" */ '../pages/UserVerificationPage.vue')
    },
    {
      path: '/catalog/products',
      name: 'products',
      meta: { isProducts: 'product', requireAuthentication: true, title: 'Products catalogue' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "products" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/:id',
      name: 'productsDetail',
      meta: { requireAuthentication: true, title: 'Products details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "productsDetail" */ '../pages/ProductPage.vue')
    },
    {
      path: '/catalog/favorites',
      name: 'favorites',
      meta: { isProducts: 'favorites', requireAuthentication: true, title: 'My favorites' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/bulk-upload',
      name: 'bulkUpload',
      meta: { isProducts: 'bulk-upload', requireAuthentication: true, title: 'Orders bulk upload' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "bulkUpload" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/packages',
      name: 'packages',
      meta: { isProducts: 'packages', requireAuthentication: true, title: 'Packages' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "bulkUpload" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/packages/:id',
      name: 'packageDetail',
      meta: { requireAuthentication: true, title: 'Packages' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "bulkUpload" */ '../pages/PackageDetailPage.vue')
    },
    {
      path: '/catalog/packages/:id/:cartid',
      name: 'editPackageDetail',
      meta: { requireAuthentication: true, title: 'Packages' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "bulkUpload" */ '../pages/PackageDetailPage.vue')
    },
    {
      path: '/orders/history',
      name: 'orders-history',
      meta: { tab: 'history', requireAuthentication: true, title: 'Orders history' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Orders'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/orders/saved',
      name: 'saved-orders',
      meta: { tab: 'saved', requireAuthentication: true, title: 'Saved orders' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Orders'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/profile/details',
      name: 'details',
      meta: { tab: 'details', requireAuthentication: true, title: 'My profile' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "details" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/profile/management',
      name: 'management',
      meta: { tab: 'management', requireAuthentication: true, title: 'User management' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "management" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/profile/company',
      name: 'company',
      meta: { tab: 'company', requireAuthentication: true, title: 'My company details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "company" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/contracts',
      name: 'contracts',
      meta: { requireAuthentication: false, title: 'Contracts' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Contracts'),
      component: () => import('../pages/ContractsPage.vue')
    },
    {
      path: '/reporting',
      name: 'reporting',
      meta: { requireAuthentication: true, title: 'Reporting' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Reporting'),
      component: () => import('../pages/ReportingPage.vue')
    },
    {
      path: '/my-documents',
      name: 'MyDocumentsPage',
      meta: { requireAuthentication: true, title: 'My documents' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Documents'),
      component: () => import(/* webpackChunkName: "MyDocumentsPage" */ '../pages/MyDocumentsPage.vue')
    },
    {
      path: '/marketing-materials',
      name: 'marketingMaterials',
      meta: { requireAuthentication: true, title: 'Marketing materials' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Marketing Materials'),
      component: () => import(/* webpackChunkName: "marketingMaterials" */ '../pages/FilesPage.vue')
    },
    {
      path: '/news',
      name: 'news',
      meta: { requireAuthentication: true, title: 'News & Events' },
      beforeEnter: (to, from, next) => checkPermission(next, 'News & Events'),
      component: () => import(/* webpackChunkName: "news" */ '../pages/NewsPage.vue')
    },
    {
      path: '/training',
      name: 'training',
      meta: { requireAuthentication: true, title: 'Training & learning' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Training & Learning'),
      component: () => import(/* webpackChunkName: "training" */ '../pages/TrainingLearningPage.vue')
    },
    {
      path: '/news/:path',
      name: 'newsItem',
      meta: { requireAuthentication: true, title: 'News & Events details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'News & Events'),
      component: () => import(/* webpackChunkName: "newsItem" */ '../pages/ArticlePage.vue')
    },
    {
      path: '/training/:path',
      name: 'selectedTrainingLearning',
      meta: { requireAuthentication: true, title: 'Training & Learning details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Training & Learning'),
      component: () => import(/* webpackChunkName: "selectedTrainingLearning" */ '../pages/SelectedTrainingLearning.vue')
    },
    {
      path: '/support/:path',
      name: 'support',
      meta: { requireAuthentication: true, title: 'Help & Support' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Support'),
      component: () => import(/* webpackChunkName: "support" */ '../pages/SupportPage.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { noPortalChrome: true, title: 'Login' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "login" */ '../pages/LoginPage.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      meta: { title: 'Forgot password' },
      component: () => import(/* webpackChunkName: "forgot password" */ '../pages/ForgotPasswordPage.vue')
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      meta: { title: 'Reset password' },
      component: () => import(/* webpackChunkName: "reset password" */ '../pages/ResetPasswordPage.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      meta: { hideFooter: true, requireAuthentication: true, title: 'My cart' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "cart" */ '../pages/CartPage.vue')
    },
    {
      path: '/order-summary',
      name: 'orderSummary',
      meta: { requireAuthentication: true, title: 'Order summary' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "orderSummary" */ '../pages/OrderSummary.vue')
    },
    {
      path: '/thanks',
      name: 'thanks',
      meta: { requireAuthentication: true, title: 'Thanks' },
      component: () => import(/* webpackChunkName: "thanks" */ '../pages/ThanksPage.vue')
    },
    {
      path: '/bulk-upload-success',
      name: 'uploadsuccess',
      meta: { requireAuthentication: true, title: 'Bulk upload success' },
      component: () => import(/* webpackChunkName: "uploadsuccess" */ '../pages/ThanksPage.vue')
    },

    {
      path: '/ko',
      name: 'ko',
      meta: { requireAuthentication: true, error: true, title: 'Thanks' },
      component: () => import(/* webpackChunkName: "ko" */ '../pages/ThanksPage.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { title: 'Contact' },
      component: () => import(/* webpackChunkName: "contact" */ '../pages/ContactPage.vue')
    },
    {
      path: '/saml-error',
      name: 'saml-error',
      meta: { noPortalChrome: true, title: 'SAML error' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "saml-error" */ '../pages/ErrorSAMLPage.vue')
    },
    {
      path: '/error403',
      name: 'Error403',
      meta: { title: 'Error' },
      component: () => import(/* webpackChunkName: "error403" */ '../pages/Error403Page.vue')
    },

    // this MUST be the last route
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      meta: { title: 'Error404' },
      component: () => import(/* webpackChunkName: "error404" */ '../pages/Error404Page.vue')
    }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createRouter (store: Store<IRootState>): AsahiRouter {
  const routes = createRoutes(store)

  const router = createAsahiRouter({
    routes,
    history,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }

      if (to.query.c && from.params.productUrl === to.params.productUrl) {
        return {}
      }

      if (
        to.name === 'products' && from.name === 'products' &&
            to.path === from.path && to.query.page === from.query.page
      ) {
        return {}
      }

      if (to.hash) {
        return { el: to.hash }
      }

      return { top: 0 }
    }
  }) as AsahiRouter

  // https://next.router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next
  router.beforeEach(async (to) => {
    const DEFAULT_TITLE = constants.application.pageTitle
    nextTick(() => {
      document.title = to.meta?.title as string || DEFAULT_TITLE
    })
    // disable data fetching for ssr-rendered 404 pages
    if (store.state.main.ssrRendered && store.state.main.pageNotFound) {
      store.commit('main/SET_SSR_RENDERED', false)
    }
    store.commit('main/SET_PAGE_NOT_FOUND', true)

    return true
  })

  router.afterEach(async (to, from) => {
    store.commit('main/SET_ROUTE_LOADING', false)
    store.commit('main/SET_HISTORY', from)
    return true
  })

  router.navigate = (url: string | CustomEvent) => {
    const target =
        typeof url === 'string'
          ? new URL(url, window.location.toString())
          : new URL(url.detail?.href ? url.detail.href : url.detail, window.location.toString())
    if (
      target.origin === window.location.origin &&
        target.pathname === window.location.pathname &&
        target.search === window.location.search
    ) {
      if (target.hash === '') return
      const element = document.querySelector(target.hash) as HTMLElement
      window.scrollBy({
        top: element ? element.getBoundingClientRect().top : 0,
        left: 0,
        behavior: 'smooth'
      })
    } else if (target.origin === window.location.origin) {
      router.push(target.pathname + target.search + target.hash)
    } else {
      window.location.assign(target.href)
    }
  }

  return router
}
