import { IHolidaysConfig } from '@/store/modules/main/intefaces/IConfig'

export const AVAILABLE_NEXT_DAYS = 365
export const DEFAULT_HOLIDAYS = [
  `${new Date().getFullYear()}-01-01`,
  `${new Date().getFullYear()}-12-24`,
  `${new Date().getFullYear()}-12-25`,
  `${new Date().getFullYear()}-12-26`,
  `${new Date().getFullYear() + 1}-01-01`
]
export const getHolidayDays = (holidays?: IHolidaysConfig[]) => {
  if (holidays != null && holidays?.length > 0) {
    let result = [] as any
    for (const value of holidays?.values()) {
      result = Object.values(value)
    }
    const final = result?.map((item: string) => item.includes(',') ? item.split(',') : item)
      .filter((item: string) => item)
      .filter((i: string) => i !== 'Months').flat()
    return final?.length > 0 ? final.map((holidays: string) => holidays.trim()).sort() : DEFAULT_HOLIDAYS
  } else {
    return DEFAULT_HOLIDAYS
  }
}

export const getUTCDate = (date: Date): string => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

const isWorkingDay = (date: Date, holidayDays: string[] = []): boolean => {
  const day = date.getDay()
  return !(day === 6 || day === 0 || holidayDays.includes(getUTCDate(date)))
}

const getFirstWorkingFromStartDay = (startDay: string, holidayDays: string[] = [], _counter = 0): number => {
  return isWorkingDay(new Date(startDay), holidayDays) ? _counter + 1 : _counter + 3
}

export const getStartDate = (holidayDays: string[] = [], firstAvailableDate: string): number => {
  return getFirstWorkingFromStartDay(firstAvailableDate, holidayDays)
}

const getMinAndMaxDays = (startDay: number, availableNextDays: number, firstAvailableDate: string) => {
  const newStartDate = new Date(firstAvailableDate)
  newStartDate.setDate(newStartDate.getDate() + (startDay - 1))
  const minDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate(), 0, 0, 0)

  const newEndDate = new Date()
  newEndDate.setDate(newEndDate.getDate() + availableNextDays)
  const maxDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), newEndDate.getDate(), 23, 59, 59)

  return { minDate, maxDate }
}

export const getMinAndMaxDayStrings = (startDay: number, availableNextDays: number, firstAvailableDate: string) => {
  const { minDate, maxDate } = getMinAndMaxDays(startDay, availableNextDays, firstAvailableDate)
  return { minDate: getUTCDate(minDate), maxDate: getUTCDate(maxDate) }
}

const convertWeekDays = (days: string[]) => {
  if (!Array.isArray(days)) return []
  const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  return days.map((day: string) => week.indexOf(day))
}

// eslint-disable-next-line
export const getDeliveryDaysOnWeek = (): number[] => {
  return convertWeekDays(['MON', 'TUE', 'WED', 'THU', 'FRI'])
}

export const validateDeliveryDate = (
  deliveryDate: string,
  availableDates: string[]
) => {
  if (deliveryDate == null || deliveryDate === '') return false

  return availableDates.includes(deliveryDate)
}
