import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { SharePointLinksActionType } from '@/store/modules/whsLink/interfaces/action-type'
import { SharePortalLinksMutationType } from '@/store/modules/whsLink/interfaces/mutation-type'
import { ISharePointState } from '@/store/modules/whsLink/interfaces/ISharePointState'
import { ISharePointMutations } from '@/store/modules/whsLink/interfaces/ISharePointMutations'
import { ISharePointResponse } from '@/store/modules/whsLink/interfaces/api/ISharePointResponse'
import { sharePointAPI } from '@/store/modules/whsLink/api/sharepoint-api'
import { ISharePointRequest } from '@/store/modules/whsLink/interfaces/api/ISharePointRequest'
import { ISharePointActions } from '@/store/modules/whsLink/interfaces/ISharePointActions'

const state = (): ISharePointState => ({
  Origin: [],
  offset: 0,
  limit: 0,
  errors: null,
  loading: false,
  total: 0
})

const mutations: MutationTree<ISharePointState> & ISharePointMutations = {
  [SharePortalLinksMutationType.SET_LOADING] (state: ISharePointState, payload: boolean) {
    state.loading = payload
  },
  [SharePortalLinksMutationType.SET_SHAREPOINTLINKS] (state: ISharePointState, payload: ISharePointResponse) {
    state.Origin = payload
  },

  [SharePortalLinksMutationType.SET_ERROR] (state: ISharePointState, payload: any) {
    state.errors = payload
  }
}

const actions: ActionTree<ISharePointState, IRootState> & ISharePointActions = {
  async [SharePointLinksActionType.FETCH_SHAREPOINTLINKS] ({ commit }, dto: ISharePointRequest) {
    commit(SharePortalLinksMutationType.SET_LOADING, true)
    const response = await sharePointAPI.getSharePointLinks(dto)
    commit(SharePortalLinksMutationType.SET_SHAREPOINTLINKS, response)
    commit(SharePortalLinksMutationType.SET_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
