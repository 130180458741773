import { IPermissionsConfig } from '@/store/modules/main/intefaces/IConfig'

export function getValueByLabel (permissions : IPermissionsConfig[], key : string): boolean {
  if (permissions.length) {
    if (key === 'Home Page') {
      return true
    }
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].label === key && permissions[i].value === '1') {
        return true
      }
    }
  }
  return false
}
