import gql from 'graphql-tag'

export const fetchPackagesQuery = gql`
query (
    $sku: [String], 
    $search: String, 
    $sort: ProductAttributeSortInput, 
    $brand: [String], 
    $category_id: [String], 
    $category_uid: [String], 
    $pack_format: [String], 
    $filter_packaging: [String], 
    $packaging: [String], 
    $volume: [String], 
    $currentPage: Int!, 
    $pageSize: Int = 25, 
    $product_last: String, 
    $product_new: String, 
    $product_sale: String, 
    $packages_flag: String, 
    $package_type: [String], 
    $dimension: [String]) {
    products(
      search: $search
      filter: {sku: {in: $sku}, 
      brand: {in: $brand}, 
      pack_format: {in: $pack_format}, 
      category_id: {in: $category_id}, 
      category_uid: {in: $category_uid}, 
      filter_packaging: {in: $filter_packaging}, 
      packaging: {in: $packaging}, 
      volume: {in: $volume}, 
      dimension: {in: $dimension}, 
      product_last: {eq: $product_last}, 
      product_new: {eq: $product_new}, 
      product_sale: {eq: $product_sale}, 
      packages_flag: {eq: $packages_flag}, 
      package_type: {in: $package_type}
    }
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      total_count
      items {
        sap_sub_brand_code
        package_type
        name
        deliveryDate
        sku
        package_prices {
          id
          package_price_discounted
          price
          subproducts {
              your_price
              your_kit_price
              sku
              __typename
          }
          __typename
        }
        pack_format
        description {
          html
          __typename
        }
        small_image {
          url
          __typename
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
          __typename
        }       
        __typename
      }
      page_info {
        page_size
        total_pages
        current_page
        __typename
      }
      __typename
    }
  }
`
