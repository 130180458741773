import { apolloClient } from '@/store/graphql.config'
import { getOrdersAPI, uploadBulkOrderAPI } from '@/store/modules/orders/services/orders.api'
import { IFetchSavedOrdersResponse } from './interfaces/api/fetchSavedOrders/IFetchSavedOrdersResponse'
import { IFetchOrderDetailResponse } from './interfaces/api/fetchSavedOrders/IFetchOrderDetailResponse'
import { ISavedOrders } from './interfaces/ISavedOrders'
import { IOrder, IOrderListRequest, ISavedOrdersListRequest, IUploadBulkOrder } from './interfaces/orders.interface'
import {
  fetchSavedOrdersQuery,
  fetchSavedOrdersDetailQuery,
  fetchSavedOrdersShipToFilterQuery
} from './queries/fetchSavedOrders.query'
import { reOrderSavedOrder } from '@/store/modules/orders/queries/reOrderSavedOrder.query'
import { deleteSavedOrder } from '@/store/modules/orders/queries/deleteSavedOrder.query'
import { IOrderDetail } from './interfaces/IOrderDetail'
import { IFetchOrderDetailRequest } from './interfaces/api/fetchSavedOrders/IFetchOrderDetailRequest'
import { IReOrderSavedOrderRequest } from '@/store/modules/orders/interfaces/api/reorderSavedOrder/IReOrderSavedOrderRequest'
import { IReOrderSavedOrderResponse } from '@/store/modules/orders/interfaces/api/reorderSavedOrder/IReOrderSavedOrderResponse'
import { IDeleteSavedOrderRequest } from '@/store/modules/orders/interfaces/api/deleteSavedOrder/IDeleteSavedOrderRequest'
import { IDeleteSavedOrderResponse } from '@/store/modules/orders/interfaces/api/deleteSavedOrder/IDeleteSavedOrderResponse'
import { OrderMutationType } from './interfaces/mutation-type'
import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { AugmentedOrderActionType, IOrderActions } from './interfaces/IOrderAction'
import { OrderActionType } from './interfaces/action-type'
import { reOrderFromHistory } from './mutations/reOrderFromHistory.mutation'
import { IReOrderHistoryOrderResponse } from './interfaces/api/reorderHistoryOrder/IReOrderHistoryOrderResponse'
import { IReOrderHistoryOrderRequest } from './interfaces/api/reorderHistoryOrder/IReOrderHistoryOrderRequest'
import { cancelSavedOrderMutation } from '@/store/modules/orders/mutations/cancelSavedOrder.mutation'
import { ICancelHistoryOrderResponse } from '@/store/modules/orders/interfaces/api/cancelHistoryOrder/ICancelHistoryOrderResponse'
import { ICancelHistoryOrderRequest } from '@/store/modules/orders/interfaces/api/cancelHistoryOrder/ICancelHistoryOrderRequest'
import { IDropdownItems } from '../common/interface/IDropdownItems'
import { IProductInfo } from '../catalog/interfaces/products/IProduct'

export interface IOrdersState {
  ordersList: IOrder[]
  totalOrders: number
  savedOrderList: ISavedOrders[]
  shipToFilterList: IDropdownItems[]
  totalSavedOrders: number
  savedOrderDetails: IOrderDetail | null
  reOrderSavedOrder: IReOrderSavedOrderResponse
  deleteSavedOrder: IDeleteSavedOrderResponse
  loading: boolean
  reOrderHistoryOrder: IReOrderHistoryOrderResponse
  cancelSavedOrder: ICancelHistoryOrderResponse
  savedOrderPageInfo: IProductInfo
  bulkOrderResponse: any
}

const state = (): IOrdersState => ({
  ordersList: [],
  totalOrders: 0,
  savedOrderList: [],
  shipToFilterList: [],
  totalSavedOrders: 0,
  savedOrderDetails: null,
  reOrderSavedOrder: {
    reOrderSavedOrder: {
      message: '',
      status: ''
    }
  },
  deleteSavedOrder: {
    deleteSavedOrder: {
      message: '',
      status: ''
    }
  },
  loading: false,
  reOrderHistoryOrder: {} as IReOrderHistoryOrderResponse,
  cancelSavedOrder: {} as ICancelHistoryOrderResponse,
  savedOrderPageInfo: {
    page_size: 10,
    total_pages: 1,
    current_page: 1
  },
  bulkOrderResponse: {}
})

const getters = {
  getOrders (state: IOrdersState) {
    return state.ordersList
  }
}

const actions: ActionTree<IOrdersState, IRootState> & IOrderActions = {

  async [OrderActionType.FETCH_ORDERS] ({ commit }: AugmentedOrderActionType, payload: IOrderListRequest) {
    try {
      commit(OrderMutationType.SET_LOADING, true)
      const response = await getOrdersAPI(payload)
      commit(OrderMutationType.SET_ORDER_LIST, response.sopList)
      commit(OrderMutationType.SET_TOTAL_ORDERS, response.total)
      commit(OrderMutationType.SET_LOADING, false)
    } catch (error) {
      console.error(error)
    }
  },
  async [OrderActionType.UPLOAD_BULK_ORDER] ({ commit }: AugmentedOrderActionType, payload: IUploadBulkOrder) {
    try {
      commit(OrderMutationType.SET_LOADING, true)
      commit(OrderMutationType.SET_BULK_UPLOAD, {})
      const response = await uploadBulkOrderAPI(payload)
      commit(OrderMutationType.SET_BULK_UPLOAD, response)

      commit(OrderMutationType.SET_LOADING, false)
    } catch (error) {
      console.error(error)
    }
  },
  async [OrderActionType.FETCH_SAVED_ORDERS] ({ commit }: AugmentedOrderActionType, payload: ISavedOrdersListRequest) {
    commit(OrderMutationType.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchSavedOrdersResponse>({
      query: fetchSavedOrdersQuery,
      variables: {
        date_from: '',
        date_to: '',
        deliver_to: '',
        shipto_name: '',
        bill_to: '',
        ...payload
      }
    })
    commit(OrderMutationType.SET_SAVED_ORDERS, data.getSaveOrderForLater?.items)
    commit(OrderMutationType.SET_SAVED_ORDER_PAGE_INFO, data.getSaveOrderForLater?.page_info)
    commit(OrderMutationType.SET_LOADING, false)
  },
  async [OrderActionType.FETCH_SHIP_TO_FILTER] ({ commit }: AugmentedOrderActionType) {
    commit(OrderMutationType.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchSavedOrdersResponse>({
      query: fetchSavedOrdersShipToFilterQuery
    })
    commit(OrderMutationType.SET_SHIP_TO_FILTER, data.getSaveOrderForLater?.items)
    commit(OrderMutationType.SET_LOADING, false)
  },
  async [OrderActionType.FETCH_SAVED_ORDER_DETAILS] (
    { commit }: AugmentedOrderActionType,
    payload: IFetchOrderDetailRequest
  ) {
    commit(OrderMutationType.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchOrderDetailResponse>({
      query: fetchSavedOrdersDetailQuery,
      variables: {
        cart_id: payload?.cart_id
      }
    })
    if (data?.getSaveOrderDetails) {
      data.getSaveOrderDetails.sap_payment_details = JSON.parse(data.getSaveOrderDetails.sap_payment_details)
    }
    commit(OrderMutationType.SET_SAVED_ORDER_DETAILS, data.getSaveOrderDetails)
    commit(OrderMutationType.SET_LOADING, false)
  },
  async [OrderActionType.REORDER_SAVED_ORDER] ({ commit }, dto: IReOrderSavedOrderRequest) {
    const { data } = await apolloClient.query<IReOrderSavedOrderResponse, IReOrderSavedOrderRequest>({
      query: reOrderSavedOrder,
      variables: {
        cart_id: dto?.cart_id
      }
    })
    commit(OrderMutationType.SET_REORDER_SAVED_ORDER, data)
  },
  async [OrderActionType.DELETE_SAVED_ORDER] ({ commit }, dto: IDeleteSavedOrderRequest) {
    const { data } = await apolloClient.query<IDeleteSavedOrderResponse, IDeleteSavedOrderRequest>({
      query: deleteSavedOrder,
      variables: {
        cart_id: dto?.cart_id
      }
    })
    commit(OrderMutationType.SET_DELETE_SAVED_ORDER, data)
  },
  async [OrderActionType.REORDER_HISTORY_ORDER] ({ commit }, dto: IReOrderHistoryOrderRequest) {
    const { data } = await apolloClient.query<IReOrderHistoryOrderResponse, IReOrderHistoryOrderRequest>({
      query: reOrderFromHistory,
      variables: {
        orderNumber: dto?.orderNumber
      }
    })
    if (data?.reorderItems) {
      commit(OrderMutationType.SET_REORDER_HISTORY_ORDER, data?.reorderItems)
    }
  },

  async [OrderActionType.CANCEL_HISTORY_ORDER] ({ commit }, dto: ICancelHistoryOrderRequest) {
    try {
      commit(OrderMutationType.SET_LOADING, true)
      const { data } = await apolloClient.mutate<ICancelHistoryOrderResponse, ICancelHistoryOrderRequest>({
        mutation: cancelSavedOrderMutation,
        variables: {
          sap_id: dto?.sap_id
        }
      })
      if (data?.cancelOrder?.message) {
        commit(OrderMutationType.SET_CANCEL_SAVED_ORDER, data)
        commit(OrderMutationType.SET_LOADING, false)
      }
    } catch (error) {
      commit(OrderMutationType.SET_LOADING, false)
      console.error('cancelorder  type error', error)
    }
  }
}

export interface IOrdersMutations<S = IOrdersState> {
  [OrderMutationType.SET_ORDER_LIST](state: S, payload: IOrder[]): void
  [OrderMutationType.SET_TOTAL_ORDERS](state: S, payload: number): void
  [OrderMutationType.SET_SAVED_ORDERS](state: S, payload: ISavedOrders[]): void
  [OrderMutationType.SET_SHIP_TO_FILTER](state: S, payload: ISavedOrders[]): void
  [OrderMutationType.SET_SAVED_ORDER_PAGE_INFO](state: S, payload: any): void
  [OrderMutationType.SET_TOTAL_SAVED_ORDERS](state: S, payload: number): void
  [OrderMutationType.SET_SAVED_ORDER_DETAILS](state: S, payload: IOrderDetail): void
  [OrderMutationType.SET_LOADING](state: S, payload: boolean): void
  [OrderMutationType.SET_REORDER_SAVED_ORDER](state: S, payload: IReOrderSavedOrderResponse): void
  [OrderMutationType.SET_DELETE_SAVED_ORDER](state: S, payload: IDeleteSavedOrderResponse): void
  [OrderMutationType.SET_REORDER_HISTORY_ORDER](state: S, payload: IReOrderHistoryOrderResponse): void
  [OrderMutationType.SET_CANCEL_SAVED_ORDER](state: S, payload: ICancelHistoryOrderResponse): void
  [OrderMutationType.SET_BULK_UPLOAD](state: S, payload: any): void
}

const mutations: MutationTree<IOrdersState> & IOrdersMutations = {
  [OrderMutationType.SET_ORDER_LIST] (state, payload) {
    state.ordersList = payload
  },
  [OrderMutationType.SET_TOTAL_ORDERS] (state, payload) {
    state.totalOrders = payload
  },
  [OrderMutationType.SET_SAVED_ORDERS] (state, payload) {
    state.savedOrderList = payload
  },
  [OrderMutationType.SET_SHIP_TO_FILTER] (state, payload) {
    const shipto_name = !!payload && payload.map((item: any) => {
      return {
        label: item.shipto_name,
        value: item.shipto_name
      }
    })
    state.shipToFilterList = shipto_name
  },
  [OrderMutationType.SET_SAVED_ORDER_PAGE_INFO] (state, payload) {
    state.savedOrderPageInfo = payload
  },
  [OrderMutationType.SET_TOTAL_SAVED_ORDERS] (state, payload) {
    state.totalSavedOrders = payload
  },
  [OrderMutationType.SET_SAVED_ORDER_DETAILS] (state, payload) {
    state.savedOrderDetails = payload
  },
  [OrderMutationType.SET_LOADING] (state, payload) {
    state.loading = payload
  },
  [OrderMutationType.SET_REORDER_SAVED_ORDER] (state, payload) {
    state.reOrderSavedOrder = payload
  },
  [OrderMutationType.SET_DELETE_SAVED_ORDER] (state, payload) {
    state.deleteSavedOrder = payload
  },
  [OrderMutationType.SET_REORDER_HISTORY_ORDER] (state, payload) {
    state.reOrderHistoryOrder = payload
  },
  [OrderMutationType.SET_CANCEL_SAVED_ORDER] (state, payload) {
    state.cancelSavedOrder = payload
  },
  [OrderMutationType.SET_BULK_UPLOAD] (state, payload) {
    state.bulkOrderResponse = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
