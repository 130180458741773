import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'
import { ISharePointResponse } from '@/store/modules/whsLink/interfaces/api/ISharePointResponse'
import { ISharePointRequest } from '@/store/modules/whsLink/interfaces/api/ISharePointRequest'

export const sharePointAPI = {
  async getSharePointLinks (dto: ISharePointRequest): Promise<ISharePointResponse> {
    const response: AxiosResponse<ISharePointResponse> = await generalServiceAPI.get<ISharePointResponse>('sharepointLinks', { params: dto })
    return response.data
  }
}
