import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'
import { IDocumentResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'

export const documentsAPI = {
  async getDocuments (dto: IDocumentRequest): Promise<IDocumentResponse> {
    const response: AxiosResponse<IDocumentResponse> = await generalServiceAPI.get('mydocuments/GetAllDocuments', { params: dto })
    return response.data
  },
  async getDocumentTypes (dto: IDocumentRequest) {
    const response = await generalServiceAPI.get('typeofdocument', { params: dto })
    return response.data
  },
  async downloadDocument (documentId: string): Promise<string> {
    const response: AxiosResponse<string> = await generalServiceAPI.get(`mydocuments/DownloadDocument/${documentId}`)
    return response.data
  }
}
