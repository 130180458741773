export const constants = {
  menuWithoutLogin: process.env.VUE_APP_MENU_WITHOUT_LOGIN?.split(','),
  // TODO: get rid of the hardcoded values
  saml: {
    empties: process.env.VUE_APP_EMPTIES_REDIRECT_URL
  },
  application: {
    theme: process.env.VUE_APP_THEME,
    contactUsEmail: process.env.VUE_APP_CONTACT_US_MAIL,
    contactUs: process.env.VUE_APP_CONTACT_US,
    defaulLang: 'hu', // TODO default language should be 'hu' but magento store is not prepared yet
    pageTitle: 'Home page',
    powerBiSchema: process.env.VUE_APP_ABOUT_POWER_BI_SCHEMA
  },
  gigyaConfig: {
    screenSet: process.env.VUE_APP_SCREENSET,
    startScreen: process.env.VUE_APP_STARTSCREEN,
    forgotScreen: process.env.VUE_APP_FORGOTSCREEN,
    resetScreen: process.env.VUE_APP_RESETSCREEN
  },
  gtm: {
    containerId: process.env.VUE_APP_GTM_CONTAINER_ID
  },
  api: {
    cdcService: process.env.VUE_APP_CDC_SERVICE,
    magnoliaService: process.env.VUE_APP_MAGNOLIA_SERVICE,
    generalservice: process.env.VUE_APP_GENERAL_SERVICE
  },
  customertype: {
    WHS: 'WHS',
    POS_HORECA: 'POS Horeca',
    POS_FOOD: 'POS Food'
  }
} as const
