export enum DocumentMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  SET_TESI_DOCUMENTS = 'SET_TESI_DOCUMENTS',
  SET_CABLOG_DOCUMENTS = 'SET_CABLOG_DOCUMENTS',
  SET_ERROR = 'SET_ERROR',
  SET_INVOICE_DOCUMENTS = 'SET_INVOICE_DOCUMENTS',
  SET_CREDIT_DOCUMENTS = 'SET_CREDIT_DOCUMENTS',
  SET_SHIPPING_DOCUMENTS = 'SET_SHIPPING_DOCUMENTS',
  SET_OTHER_DOCUMENTS = 'SET_OTHER_DOCUMENTS',
  SET_SEARCH = 'SET_SEARCH',
  SET_FILTERS = 'SET_FILTERS',
  SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES'
}
