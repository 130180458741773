import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72891873"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-icon" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_icon = _resolveComponent("asahi-icon")!
  const _component_asahi_typography = _resolveComponent("asahi-typography")!
  const _component_asahi_button = _resolveComponent("asahi-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_asahi_icon, {
            icon: _ctx.isErrorModal ? 'info-circle' : 'check-circle',
            size: "32"
          }, null, 8, ["icon"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createVNode(_component_asahi_typography, { type: "headline-1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.message)), 1)
              ]),
              _: 1
            })
          ], true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "body", {}, () => [
            _createVNode(_component_asahi_typography, { type: "body-1-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.subMessage)), 1)
              ]),
              _: 1
            })
          ], true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            (_ctx.showButton)
              ? (_openBlock(), _createBlock(_component_asahi_button, {
                  key: 0,
                  color: "primary",
                  class: "products-button",
                  onClick: _ctx.close
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonText)), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], true)
        ])
      ])
    ])
  ]))
}