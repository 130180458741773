import { constants } from '@/constants'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { IPortalHardCodeLink } from '@/store/modules/links/interfaces/IPortalHardCodeLink'
import { RolePermissionDetails } from '@/store/modules/users/interfaces/IUser'
import { getValueByLabel } from '@/utils/permissions'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  data: () => ({
    clientWidth: 0,
    customerRoleType: constants.customertype
  }),
  computed: {
    ...mapState('main', ['permissions']),
    user () {
      return this.$store.state.auth.user
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    customerType (): any {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return []
    },
    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    menu () {
      return [
        {
          // label: 'Vytvořit objednávku',
          // href: '/catalog/products',
          name: 'xx',
          isActive: true,
          // icon: 'shopping-bag',
          children: [
            ...this.checkPermission('Home Page', {
              label: 'Hlavní stránka',
              href: '/',
              name: 'home',
              isActive: false,
              icon: 'home-alt'
            }),
            ...this.checkPermission('My Profile', {
              label: 'Můj profil',
              href: '/profile/details',
              name: 'profile',
              isActive: false,
              icon: 'user'
            }),
            ...(
              this.checkPermission('Catalogue', {
                label: 'Katalog',
                name: 'catalog',
                isActive: false,
                icon: 'beer',
                children: [
                  {
                    label: 'Produkty',
                    href: '/catalog/products',
                    name: 'products',
                    isActive: false
                  },
                  {
                    label: 'Moje oblíbené',
                    href: '/catalog/favorites',
                    name: 'favorites',
                    isActive: false
                  },
                  {
                    label: 'Packages',
                    href: '/catalog/packages',
                    name: 'packages',
                    isActive: false
                  }
                ]
              })),

            ...(this.checkPermission('My Orders', {
              label: 'Pedidos',
              name: 'orders',
              isActive: false,
              icon: 'shopping-bag',
              children: [
                {
                  label: 'Historie objednávek',
                  href: '/orders/history',
                  name: 'history',
                  isActive: false
                },
                {
                  label: 'Pedidos guardados no enviados',
                  href: '/orders/saved',
                  name: 'savedOrders',
                  isActive: false
                }
              ]
            })),

            ...this.checkPermission('My Documents',
              {
                label: 'Moje dokumenty',
                href: '/my-documents',
                name: 'myDocuments',
                isActive: false,
                icon: 'newspaper'
              }),

            ...this.checkPermission('Reporting', {
              label: 'Přehled odběrů',
              href: '/reporting',
              name: 'reporting',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Contracts',
              {
                label: 'Přehled smluv',
                href: '/contracts',
                name: 'contracts',
                isActive: false,
                icon: 'file-contract'
              }),
            ...this.checkPermission('Support', {
              label: 'Podpora',
              href: '/support/faq',
              name: 'support',
              isActive: false,
              icon: 'question-circle',
              children: [
                {
                  label: 'Otázky a odpovědi',
                  href: '/support/faq',
                  name: 'faq',
                  isActive: false
                },
                {
                  label: 'O portálu',
                  href: '/support/knowledge-base',
                  name: 'knowledgeBase',
                  isActive: false
                },
                {
                  label: 'Kontaktujte nás',
                  href: '/support/tickets',
                  name: 'supportTickets',
                  isActive: false
                }
              ]
            }),
            ...this.checkPermission('Marketing Materials', {
              label: 'Materiály pro Vás',
              href: '/marketing-materials',
              name: 'marketingMaterials',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Training & Learning', {
              label: 'Formazione',
              name: 'trainingLearning',
              href: '/training',
              icon: 'book-open'
            }),
            ...this.checkPermission('News & Events', {
              label: 'Novinky a články',
              href: '/news',
              name: 'newsEvents',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('About Dreher', {
              label: 'a dreher sörfőzdékről',
              href: this.getLinkByName('aboutDreherMenu').url,
              name: 'aboutDreherMenu',
              isActive: false,
              icon: 'university'
            }),
            ...this.checkPermission(
              'Empties Management',
              {
                label: 'Portale Materiale Circolante',
                href: constants.saml.empties,
                name: 'emptiesManagement',
                isActive: false,
                icon: 'external-link-alt'
              },
              this.isOnPremiseEmptiesUser || this.isOnPremiseFranchiseEmptiesUser
            ),
            ...this.checkPermission(
              'Academy',
              {
                label: 'Akadémia Prvý pri zdroji',
                href: this.getLinkByName('eLearning').url,
                name: 'eLearning',
                isActive: false,
                icon: 'external-link-alt'
              },
              this.isWHS || this.isPOSFood || this.isPOSHoreca
            ),
            ...this.checkPermission(
              'Menu Creator',
              {
                label: 'Prazdroj menu',
                href: this.getLinkByName('pubMenu').url,
                name: 'pubMenu',
                isActive: false,
                icon: 'book-open'
              },
              this.isPOSHoreca
            ),
            ...this.checkPermission('Sharepoint', {
              label: 'WHS portál',
              name: 'WHSPortal',
              isActive: false,
              icon: 'external-link-alt'
            })
          ]
        },
        ...this.checkPermission('Catalogue', {
          label: 'Vytvořit objednávku',
          href: '/catalog/products',
          name: 'createAnOrder',
          isActive: true,
          icon: 'shopping-bag'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'MI CARRITO',
          name: 'cart',
          ...(this.clientWidth >= 1000
            ? {
                href: '',
                precart: true
              }
            : {
                href: '/cart'
              }),
          icon: 'shopping-cart'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Moje oblíbené',
          name: 'favoritesMenu',
          href: '/catalog/favorites',
          icon: 'heart'
        })
      ]
    },
    isOnPremiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Empties Management')
      )
    },
    isOnPremiseFranchiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Franchisee Empties Management')
      )
    },
    isWHS () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === this.customerRoleType.WHS)
      )
    },
    isPOSFood () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === this.customerRoleType.POS_FOOD)
      )
    },
    isPOSHoreca () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === this.customerRoleType.POS_HORECA)
      )
    }
  },
  methods: {
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) {
        localStorage.setItem('customertype', this.customerType?.customerType)
      }

      if (this.isWHS && permission === 'Catalogue' && value.children) {
        value.children = value.children.filter((child: { label: string }) => child.label !== 'Packages')
      }

      if (
        this.allPermission?.includes(permission) &&
        additionalPremmision &&
        getValueByLabel(this.permissions, permission)
      ) {
        return [value]
      } else {
        return []
      }
    },
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },
    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    },
    fetchPortalHardCodeLink () {
      this.$store.dispatch(LinksActionType.FETCH_PORTAL_HARD_CODE_LINK)
    }
  }
})
