
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MessageModal',
  props: {
    message: {
      type: String,
      required: true
    },
    subMessage: {
      type: String,
      default: '',
      required: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'OK'
    },
    isErrorModal: {
      type: Boolean,
      required: false,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  emits: ['close'],

  methods: {
    close () {
      this.closeModal()
      this.$emit('close')
    }
  }
})
