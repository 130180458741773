import { generalServiceAPI } from '@/store/api-instance'
import { IGetAvailableDateRequest } from '../interfaces/getAvailableDates/IGetAvailableDateRequest'
import { AxiosResponse } from 'axios'

async function getAvailableDateAPI (queryParams: IGetAvailableDateRequest) {
  const response: AxiosResponse = await generalServiceAPI.get('orders/GetFirstAvailableDate', { params: queryParams })
  return response.data
}

async function getCalendarAvailableDaysAPI (queryParams: IGetAvailableDateRequest) {
  const response: AxiosResponse = await generalServiceAPI.get('orders/GetCalendarAvailableDays', { params: queryParams })
  return response.data
}
export {
  getAvailableDateAPI,
  getCalendarAvailableDaysAPI
}
