import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { DocumentActionType } from '@/store/modules/documents/interfaces/action-type'
import { DocumentMutationType } from '@/store/modules/documents/interfaces/mutation-type'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IDocumentsMutations } from '@/store/modules/documents/interfaces/IDocumentsMutations'
import { IDocumentResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { documentsAPI } from '@/store/modules/documents/api/documents-api'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IDocumentsActions } from '@/store/modules/documents/interfaces/IDocumentsActions'
import { IFilter } from '../orders/interfaces/IFilter'
import { IDropdownItems } from '@/store/modules/common/interface/IDropdownItems'

const state = (): IDocumentsState => ({
  documents: [],
  documentTypes: [],
  total: 0,
  loading: false,
  errors: null,
  filters: {} as IFilter
})

const mutations: MutationTree<IDocumentsState> & IDocumentsMutations = {
  [DocumentMutationType.SET_LOADING] (state: IDocumentsState, payload: boolean) {
    state.loading = payload
  },
  [DocumentMutationType.SET_DOCUMENTS] (state: IDocumentsState, payload: IDocumentResponse) {
    state.documents = payload.documentList
    state.total = payload?.total
  },
  [DocumentMutationType.SET_DOCUMENT_TYPES] (state: IDocumentsState, payload: IDropdownItems[]) {
    state.documentTypes = payload
  },
  [DocumentMutationType.SET_ERROR] (state: IDocumentsState, payload: any) {
    state.errors = payload
  },
  [DocumentMutationType.SET_FILTERS] (state: IDocumentsState, payload: IFilter) {
    state.filters = payload
  }
}

const actions: ActionTree<IDocumentsState, IRootState> & IDocumentsActions = {
  async [DocumentActionType.FETCH_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDocuments(dto)
      commit(DocumentMutationType.SET_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
    }
    commit(DocumentMutationType.SET_LOADING, false)
  },
  async [DocumentActionType.FETCH_DOCUMENT_TYPES] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDocumentTypes(dto)
      commit(DocumentMutationType.SET_DOCUMENT_TYPES, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
    }
    commit(DocumentMutationType.SET_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
