import gql from 'graphql-tag'

export const fetchProductQuery = gql`
  query ($sku: String) {
    products(filter: { sku: { eq: $sku } }, pageSize: 25, currentPage: 1, sort: { name: ASC }) {
      items {
        package_type
        name
        multi_deposit
        material_type
        sku
        pack_format
        number_of_products_on_layer
        number_of_pieces_in_crate
        number_of_pieces_in_carton
        package_prices {
          id
          package_price_discounted
          price
          subproducts {
              your_price
              your_kit_price
              sku
              __typename
          }
          __typename
        }
        description {
          html
        }
        features {
          custom_color
          food_pairing
          conservation_term
          alcohol_content
          beer_type
          fermentation
          color
          taste
          allergen_info
          nutritional_info
          serving_temperature
          price_segment
        }
        your_price
        your_price_tax
        redemption_fee
        multi_deposit_z099
        downloads
        favourites
        promo
        weight
        sap_sub_brand_code
        packaging
        number_of_items_on_pallet
        product_name_sap
        alcohol_content
        volume
        dimension
        catalog_product_name
        product_last
        product_new
        product_sale
        categories{
          name
        }
        thumbnail {
          url
        }
        image {
          url
        }
        media_gallery {
          url
        }
        ... on BundleProduct {
          your_price_sum
          your_price_sum_tax
          bundle_price_sum
          bundle_price_sum_tax
          bundle_fields {
            minimum_amt
            valid_from
            valid_to
            limited_amount
          }
          items {
            title
            sku
            type
            required
            position
            your_price_sum
            your_price_sum_tax
            options {
              uid
              id
              label
              quantity
              price
              position
              product {
                id
                name
                sku
                image {
                  url
                  __typename
                }
                your_price
                your_price_tax
              }
            }
          }
        }
      }
      page_info {
        page_size
      }
    }
  }
`
