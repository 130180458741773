import gql from 'graphql-tag'

export const fetchTransportTypeMutation = gql(`
mutation ($cart_id: String!, $transporttype: String!, $customertype: String!, $sappaymentcode: String!, $sappaymentmethod: String!) {
  setTransportType(
      cart_id: $cart_id
      transporttype: $transporttype
      customertype: $customertype,
      sappaymentcode: $sappaymentcode,
      sappaymentmethod: $sappaymentmethod
  ) {
    message
  }
}
`)
