export enum BasketActionTypes {
  'CREATE_EMPTY_CART' = 'CREATE_EMPTY_CART',
  'FETCH_CART_QUERY' = 'FETCH_CART_QUERY',
  'FETCH_PRODUCTS' = 'FETCH_PRODUCTS',
  'FETCH_BASKET_ID' = 'FETCH_BASKET_ID',
  'FETCH_ADD_TO_BASKET' = 'FETCH_ADD_TO_BASKET',
  'REMOVE_ITEM' = 'REMOVE_ITEM',
  'UPDATE_ITEMS' = 'UPDATE_ITEMS',
  'FETCH_BILLING_ADDRESS' = 'FETCH_BILLING_ADDRESS',
  'FETCH_SHIPPING_ADDRESS' = 'FETCH_SHIPPING_ADDRESS',
  'FETCH_SHIPPING_METHODS' = 'FETCH_SHIPPING_METHODS',
  'SET_PAYMENT_METHOD' = 'SET_PAYMENT_METHOD',
  'PLACE_ORDER' = 'PLACE_ORDER',
  'FETCH_TRANSPORT_TYPE' = 'FETCH_TRANSPORT_TYPE',
  'GET_FIRST_AVAILABLE_DATE' = 'GET_FIRST_AVAILABLE_DATE',
  'GET_AVAILABLE_DAYS' = 'GET_AVAILABLE_DAYS',
  SAVE_ORDER_FOR_LATER = 'SAVE_ORDER_FOR_LATER',
  FETCH_MIN_CART_QUERY_FOR_CATALOG = 'FETCH_MIN_CART_QUERY_FOR_CATALOG',
  VALIDATE_PRICES = 'VALIDATE_PRICES',
  UPDATE_PRICES = 'UPDATE_PRICES',
  FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD',
  FETCH_ORDER_TYPE = 'FETCH_ORDER_TYPE',
  FETCH_ORDER_REASON = 'FETCH_ORDER_REASON'

}
