
import { defineComponent } from 'vue'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'
import { constants } from '@/constants'

export default defineComponent({
  name: 'BeforeLoginLayout',

  data: () => ({
    activeLanguage: (localStorage.getItem('lang') || (constants.application.defaulLang)) as LanguagesType,
    theme: constants.application.theme
  }),

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    languages () {
      return ['en', 'hu']
    }
  },

  mounted () {
    localStorage.setItem('lang', this.activeLanguage)
  },

  methods: {
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    }
  }
})
