import gql from 'graphql-tag'

export const fetchProductsQuery = gql`
  query (
    $sku: [String]
    $search: String
    $sort: ProductAttributeSortInput
    $brand: [String]
    $category_uid: [String]
    $pack_format: [String]
    $currentPage: Int!
    $pageSize: Int = 25
    $product_last: String
    $product_new: String
    $product_sale: String
    $dimension: [String]
  ) {
    products(
      search: $search
      filter: {
        sku: { in: $sku }
        brand: { in: $brand }
        pack_format: {in : $pack_format}
        category_uid: { in: $category_uid }
        dimension: { in: $dimension }
        product_last: { eq: $product_last }
        product_new: { eq: $product_new }
        product_sale: { eq: $product_sale }
      }
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      total_count
      items {
        sap_sub_brand_code
        alcohol_content
        dimension
        multi_deposit
        material_type
        name
        sku
        your_price
        your_price_tax
        redemption_fee
        multi_deposit_z099
        favourites
        promo
        product_last
        product_new
        product_sale
        number_of_products_on_layer
        number_of_pieces_in_crate
        number_of_pieces_in_carton
        number_of_items_on_pallet
        pack_format
        categories{
          name
        }
        description {
          html
        }
        small_image {
          url
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
        }
        ... on BundleProduct {
          your_price_sum
          bundle_price_sum
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum

            options {
              uid
              id
              label
              quantity
              price
              position

              product {
                id
                name
                sku
                your_price
                your_price_tax
              }
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`
