import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-view" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "main-view" }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "main-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppGlobalModal = _resolveComponent("AppGlobalModal")!
  const _component_UserToolbar = _resolveComponent("UserToolbar")!
  const _component_TutorialModal = _resolveComponent("TutorialModal")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_HelpActionButton = _resolveComponent("HelpActionButton")!
  const _component_ResizeObserver = _resolveComponent("ResizeObserver")!
  const _component_error401_page = _resolveComponent("error401-page")!
  const _component_BannersComponent = _resolveComponent("BannersComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_asahi_portal_chrome = _resolveComponent("asahi-portal-chrome")!
  const _component_BeforeLoginLayout = _resolveComponent("BeforeLoginLayout")!
  const _component_message_modal = _resolveComponent("message-modal")!

  return (_ctx.isLoaded)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_AppGlobalModal),
        _createVNode(_component_UserToolbar),
        _createVNode(_component_ModalComponent, {
          ref: "tutorialModal",
          showCloseButton: _ctx.user.firstLogin !== true,
          backdropDismiss: _ctx.user.firstLogin !== true,
          overFlowScroller: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TutorialModal, { closeModal: _ctx.closeTutorialModal }, null, 8, ["closeModal"])
          ]),
          _: 1
        }, 8, ["showCloseButton", "backdropDismiss"]),
        (_ctx.user?.isLoggedIn && _ctx.user?.userValidation)
          ? (_openBlock(), _createBlock(_component_HelpActionButton, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isPortalChrome && _ctx.isLoggedIn && !(_ctx.gigICode && _ctx.gigIvToken))
          ? (_openBlock(), _createBlock(_component_asahi_portal_chrome, {
              key: 1,
              theme: _ctx.theme,
              user: _ctx.user,
              menu: _ctx.menuLinks,
              dictionary: _ctx.computedDictionary,
              footerLinksSecondBlock: _ctx.footerLinks,
              footerLinksSocial: _ctx.footerLinksSocial,
              progressBarMsg: _ctx.progressBarMsg === '' ? _ctx.messageCart : _ctx.progressBarMsg,
              progressBarSuccess: _ctx.canProceed,
              showProgressBarMsg: true,
              footerLinksFirstBlock: _ctx.footerLinksBrands,
              footerLinksMobile: _ctx.footerLinksMobile,
              activeLanguage: _ctx.activeLanguage,
              showFooter: _ctx.showFooter,
              showCopyRight: true,
              copyRightMsg: _ctx.copyRightMsg,
              cartItems: _ctx.cartItems,
              favoritesItems: _ctx.favoritesItems,
              products: _ctx.items,
              languages: _ctx.languages,
              hideTutorial: !_ctx.isLoggedIn,
              showProfile: _ctx.showProfiles,
              showFooterImgRight: false,
              showFooterAgeGateText: true,
              onNavigate: _ctx.menuNavigate,
              onLanguagechange: _cache[0] || (_cache[0] = (event) => _ctx.changeLanguage(event?.detail)),
              onLogout: _ctx.userLogout,
              onLogin: _cache[1] || (_cache[1] = () => _ctx.$navigate('/login')),
              onUseredit: _ctx.userEdit,
              onRunintrotour: _ctx.openTutorialModal,
              onRemovefromcart: _cache[2] || (_cache[2] = (event) => _ctx.removeProductFromCart(event?.detail))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_ResizeObserver, { onResize: _ctx.onResizePortal }, null, 8, ["onResize"]),
                      (_ctx.notAuthorized)
                        ? (_openBlock(), _createBlock(_component_error401_page, { key: 0 }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (_ctx.$route.name === 'homepage')
                              ? (_openBlock(), _createBlock(_component_BannersComponent, { key: 0 }))
                              : _createCommentVNode("", true),
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ], 64))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["theme", "user", "menu", "dictionary", "footerLinksSecondBlock", "footerLinksSocial", "progressBarMsg", "progressBarSuccess", "footerLinksFirstBlock", "footerLinksMobile", "activeLanguage", "showFooter", "copyRightMsg", "cartItems", "favoritesItems", "products", "languages", "hideTutorial", "showProfile", "onNavigate", "onLogout", "onUseredit", "onRunintrotour"]))
          : (_ctx.isPortalChrome && !_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_BeforeLoginLayout, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view, null, {
                      default: _withCtx(({ Component }) => [
                        _createElementVNode("div", _hoisted_3, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { onSetAgeConfirmed: _ctx.setAgeConfirmed }, null, 40, ["onSetAgeConfirmed"]))
                    ])
                  ]),
                  _: 1
                })
              ])),
        (_ctx.openModal)
          ? (_openBlock(), _createBlock(_component_message_modal, {
              key: 4,
              message: _ctx.message,
              subMessage: _ctx.subMessage,
              isErrorModal: _ctx.isErrorModal,
              onClose: _cache[3] || (_cache[3] = 
        () => {
          _ctx.openModal = false
        }
      )
            }, null, 8, ["message", "subMessage", "isErrorModal"]))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}