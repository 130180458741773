import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IBasketState } from '@/store/modules/basket/interfaces/IBasketState'
import { IBasketAction } from '@/store/modules/basket/interfaces/IBasketAction'
import { IBasketMutation } from '@/store/modules/basket/interfaces/IBasketMutation'
import { BasketMutationTypes } from '@/store/modules/basket/interfaces/mutation-types'
import { IFetchBasketRequest } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketRequest'
import { BasketActionTypes } from '@/store/modules/basket/interfaces/action-types'
import { apolloClient } from '@/store/graphql.config'
import { IFetchBasketResponse } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketResponse'
import { IBasketProduct } from '@/store/modules/basket/interfaces/IBasketProduct'
import { fetchCustomerBasketQuery } from './queries/fetchCustomerBasket.query'
import { fetchProductsToCartMutation } from './mutations/fetchProductsToCart.mutation'
import { IAddToBasketResponse } from './interfaces/addProductToBasket/IAddToBasketResponse'
import { IAddToBasketRequest } from './interfaces/addProductToBasket/IAddToBasketRequest'
import { IRemoveFromBasketResponse } from './interfaces/removeFromBasket/IRemoveFromBasketResponse'
import { IRemoveFromBasketRequest } from './interfaces/removeFromBasket/IRemoveFromBasketRequest'
import { fetchRemoveCardItemMutation } from './mutations/fetchRemoveCardItem.mutation'
import { fetchUpdateCartItemsMutation } from './mutations/fetchUpdateCartItems.mutation'
import { IUpdateItemsRequest } from './interfaces/updateItems/IUpdateItemsRequest'
import { IUpdateItemsResponse } from './interfaces/updateItems/IUpdateItemsResponse'
import { fetchPlaceOrderMutation } from './mutations/fetchPlaceOrder.mutation'
import { IPlaceOrderRequest } from './interfaces/placeOrder/IPlaceOrderRequest'
import { IPlaceOrderResponse } from './interfaces/placeOrder/IPlaceOrderResponse'
import { fetchShippingAddressMutation } from './mutations/fetchShippingAddress.mutation'
import { ISetShippingAddressRequest } from './interfaces/setShippingAddress/ISetShippingAddressRequest'
import { ISetShippingAddressResponse, ShippingAddress } from './interfaces/setShippingAddress/ISetShippingAddressResponse'
import { fetchShippingMethodMutation } from './mutations/fetchShippingMethod.mutation'
import { ISetShippingMethodRequest } from './interfaces/setShippingMethod/ISetShippingMethodRequest'
import { ISetShippingMethodResponse, ShippingMethod } from './interfaces/setShippingMethod/ISetShippingMethodResponse'
import { fetchCreateBasketMutation } from './mutations/fetchCreateBasket.mutation'
import { ISetEmptyCartResponse } from './interfaces/emptyCart/ISetEmptyCartResponse'
import { fetchPaymentMethodMutation } from './mutations/fetchPaymentMethod.mutation'
import { ISetPaymentMethodRequest } from './interfaces/setPaymentMethod/ISetPaymentMethodRequest'
import { ISetPaymentMethodResponse } from './interfaces/setPaymentMethod/ISetPaymentMethodResponse'
import { fetchCartQuery } from './queries/fetchCartQuery.query'
import { ICartQueryRequest } from './interfaces/cartQuery/ICartQueryRequest'
import { AvailablePaymentMethods, ICartQueryResponse } from './interfaces/cartQuery/ICartQueryResponse'
import { IError } from '@/store/modules/catalog/interfaces/common/IError'
import { fetchTransportTypeMutation } from './mutations/fetchTransportType.mutation'
import { ISetTransportTypeRequest } from './interfaces/fetchTransportType/ISetTransportTypeRequest'
import { ISetTransportTypeResponse } from './interfaces/fetchTransportType/ISetTransportTypeResponse'
import { addSaveOrderForLaterQuery } from './queries/addSaveOrderForLater.query'
import { IAddSaveOrderForLaterRequest, IAddSaveOrderForLaterResponse } from './interfaces/addSaveOrderForLater'
import i18n from '@/i18n'
import { fetchMinCartForCatalogQuery } from './queries/fetchMinCartForCatalogQuery.query'
import { validateProductPrice } from './queries/validateProductPrice.query'
import { IValidatePricesRequest } from './interfaces/validatePrices/IValidatePricesRequest'
import { IValidatePricesResponse } from './interfaces/validatePrices/IValidatePricesResponse'
import { fetchUpdatePricesMutation } from './mutations/fetchUpdatePrices.mutation'
import { IUpdatePricesRequest } from './interfaces/updatePrices/IUpdatePricesRequest'
import { IUpdatePricesResponse } from './interfaces/updatePrices/IUpdatePricesResponse'
import { IGetAvailableDateRequest } from './interfaces/getAvailableDates/IGetAvailableDateRequest'
import { getAvailableDateAPI, getCalendarAvailableDaysAPI } from './api/getAvailableDate'
import { constants } from '@/constants'
import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'
import {
  getUTCDate
} from '@/utils/deliveryDate'
const state = (): IBasketState => ({
  id: '',
  emptyCartId: '',
  items: [],
  loading: false,
  error: null,
  query: {} as ICartQueryResponse,
  billingAddress: {},
  shippingAddress: [],
  shippingMethods: [],
  availablePaymentMethods: null,
  paymentMethod: '',
  paymentMethodCode: '',
  order: {} as IPlaceOrderResponse,
  availableDate: '',
  availableDateWithFee: '',
  availableDays: [],
  deliveryDate: '',
  shipmentId: '',
  transportType: '',
  validatedMsg: '',
  progressBarMsg: '',
  prices: {} as IUpdatePricesResponse,
  orderReason: {} as any,
  orderType: {} as any,
  shippingType: ''
})

const getters = {
  progressBarMessage: (state: IBasketState) => {
    const progressBar = state.query?.cart?.progress_bar_data
    const customerRoleType = constants.customertype
    if (progressBar) {
      const customerType = localStorage.getItem('customertype')
      if (customerType === customerRoleType.POS_HORECA) {
        if (progressBar?.iKegFlag === 'yes') {
          if (progressBar?.iTotalQty > progressBar?.iMaximumUnit) {
            return i18n.global.t('The number of units of the order exceeds the limit, reduce the quantity to proceed with the order')
          }
        } else {
          if (progressBar?.iTotalQty < progressBar?.iMinimumUnit) {
            return i18n.global.t('The minimum number of units has not been reached, please add more goods')
          } else if (progressBar?.iTotalQty > progressBar?.iMaximumUnit) {
            return i18n.global.t('The number of units of the order exceeds the limit, reduce the quantity to proceed with the order')
          }
        }
      } else if (customerType === customerRoleType.POS_FOOD) {
        if (progressBar?.iTotalQty > progressBar?.iMaximumUnit) {
          return i18n.global.t('The number of units of the order exceeds the limit, reduce the quantity to proceed with the order')
        }
        if (progressBar?.iTotalQty < progressBar?.iMinimumUnit) {
          return i18n.global.t('The minimum number of units has not been reached, please add more goods')
        }
      } else if (customerType === customerRoleType.WHS) {
        if (progressBar?.iTotalCartPalletQty < progressBar?.iMinimumOrderPallet) {
          return i18n.global.t('The minimum number of pallets has not been reached, please add more goods')
        }
        if (progressBar?.iTotalCartWeight > progressBar?.iMaximumOrderWeight) {
          return i18n.global.t('The weight of the order exceeds the limit, reduce the quantity to proceed with the order')
        }
      }
    }
    return ''
  },
  getAvailableDates: (state: IBasketState) => {
    let availableDates: string[] = []
    if (state.availableDays.length > 0) {
      availableDates = Array.from(state.availableDays, (availableDate:string) => {
        return getUTCDate(new Date(availableDate))
      })
    }
    return availableDates
  }
}

const mutations: MutationTree<IBasketState> & IBasketMutation = {
  [BasketMutationTypes.SET_EMPTY_CART] (state, payload: string): void {
    state.emptyCartId = payload
  },
  [BasketMutationTypes.SET_CART_QUERY] (state, payload: ICartQueryResponse): void {
    state.query = payload
  },
  [BasketMutationTypes.SET_BASKET_ID] (state, payload: string): void {
    state.id = payload
  },
  [BasketMutationTypes.SET_BASKET_ITEMS] (state, payload: IBasketProduct[]): void {
    state.items = payload
  },
  [BasketMutationTypes.SET_ERROR] (state, payload: unknown): void {
    state.error = payload
  },
  [BasketMutationTypes.SET_LOADING] (state, payload: boolean): void {
    state.loading = payload
  },
  [BasketMutationTypes.SET_SHIPPING_ADDRESS] (state, payload: ShippingAddress[]): void {
    state.shippingAddress = payload
  },
  [BasketMutationTypes.SET_SHIPPING_METHODS] (state, payload: ShippingMethod[]): void {
    state.shippingMethods = payload
  },
  [BasketMutationTypes.PAYMENT_METHOD] (state, payload: string): void {
    state.paymentMethod = payload
  },
  [BasketMutationTypes.PAYMENT_METHOD_CODE] (state, payload: string): void {
    state.paymentMethodCode = payload
  },
  [BasketMutationTypes.SET__AVAILABLE_PAYMENT_METHOD] (state, payload: AvailablePaymentMethods[]): void {
    state.availablePaymentMethods = payload
  },
  [BasketMutationTypes.SET_ORDER] (state, payload: IPlaceOrderResponse): void {
    state.order = payload
  },
  [BasketMutationTypes.SET_DELIVERY_DATE] (state, payload: string): void {
    state.deliveryDate = payload
  },
  [BasketMutationTypes.SET_SHIPMENT_ID] (state, payload: string): void {
    state.shipmentId = payload
  },
  [BasketMutationTypes.SET_FIRST_AVAILABLE_DATE] (state, payload: string) {
    state.availableDate = payload
  },
  [BasketMutationTypes.SET_AVAILABLE_DAYS] (state, payload: string[]) {
    state.availableDays = payload
  },
  [BasketMutationTypes.SET_FIRST_AVAILABLE_DATE_FEE] (state, payload: string) {
    state.availableDateWithFee = payload
  },
  [BasketMutationTypes.SET_TRANSPORT_TYPE] (state, payload: string): void {
    state.transportType = payload
  },
  [BasketMutationTypes.SET_VALIDATION_MESSAGE] (state, payload: string): void {
    state.validatedMsg = payload
  },
  [BasketMutationTypes.SET_PROGRESS_BAR] (state, payload: string): void {
    state.progressBarMsg = payload
  },
  [BasketMutationTypes.SET_PRICES] (state, payload: IUpdatePricesResponse): void {
    state.prices = payload
  },
  [BasketMutationTypes.ORDER_REASON] (state, payload: any): void {
    state.orderReason = payload
  },
  [BasketMutationTypes.ORDER_TYPE] (state, payload: any): void {
    state.orderType = payload
  },
  [BasketMutationTypes.SET_SHIPPING_TYPE] (state, payload: string): void {
    state.shippingType = payload
  }
}

const actions: ActionTree<IBasketState, IRootState> & IBasketAction = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [BasketActionTypes.CREATE_EMPTY_CART] ({ commit }) {
    await apolloClient.mutate<ISetEmptyCartResponse, object>({
      mutation: fetchCreateBasketMutation
    })
  },
  async [BasketActionTypes.FETCH_CART_QUERY] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchCartQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (localStorage.getItem('deliveryDate') === null && data?.cart?.delivery_date) {
      localStorage.setItem('deliveryDate', data?.cart?.delivery_date)
    }
    commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
    commit(BasketMutationTypes.SET__AVAILABLE_PAYMENT_METHOD, data?.cart?.available_payment_methods || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
  },
  async [BasketActionTypes.FETCH_MIN_CART_QUERY_FOR_CATALOG] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchMinCartForCatalogQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (localStorage.getItem('deliveryDate') === null && data?.cart?.delivery_date) {
      localStorage.setItem('deliveryDate', data?.cart?.delivery_date)
    }
    if (data?.cart?.ship_to_id) {
      localStorage.setItem('shipToId', decodeURIComponent(data?.cart?.ship_to_id))
    }
    if (data?.cart?.delivery_method) {
      localStorage.setItem('shippingMethod', data?.cart?.delivery_method)
    }
    commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
  },
  async [BasketActionTypes.FETCH_BASKET_ID] ({ commit }) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchBasketResponse, IFetchBasketRequest>({
      query: fetchCustomerBasketQuery
    })
    try {
      if (data) {
        commit(BasketMutationTypes.SET_BASKET_ID, data?.customerCart.id)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('fetch basket error', error)
    }
  },
  async [BasketActionTypes.FETCH_ADD_TO_BASKET] ({ commit }, dto: IAddToBasketRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      await apolloClient.mutate<IAddToBasketResponse, IAddToBasketRequest>({
        mutation: fetchProductsToCartMutation,
        variables: {
          cart_id: dto.cart_id,
          sku: dto.sku,
          quantity: dto.quantity,
          number_of_layer: dto.number_of_layer,
          number_of_pallet: dto.number_of_pallet,
          entered_options: dto.entered_options,
          selected_options: dto.selected_options
        }
      })
      commit(BasketMutationTypes.SET_LOADING, false)
    } catch (error: any) {
      console.error('add to cart error:', error)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      commit(BasketMutationTypes.SET_LOADING, false)
      throw new Error(error.message)
    }
  },
  async [BasketActionTypes.REMOVE_ITEM] ({ commit }, dto: IRemoveFromBasketRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.mutate<IRemoveFromBasketResponse, IRemoveFromBasketRequest>({
      mutation: fetchRemoveCardItemMutation,
      variables: {
        cart_id: dto.cart_id,
        cart_item_id: dto.cart_item_id
      }
    })
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.removeItemFromCart?.cart?.items)
    commit(BasketMutationTypes.SET_LOADING, false)
  },
  async [BasketActionTypes.UPDATE_ITEMS] ({ commit }, dto: IUpdateItemsRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.mutate<IUpdateItemsResponse, IUpdateItemsRequest>({
        mutation: fetchUpdateCartItemsMutation,
        variables: {
          cart_id: dto.cart_id,
          cart_items: dto.cart_items
        }
      })
      commit(BasketMutationTypes.SET_CART_QUERY, data?.updateCartItems?.cart)
      commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.updateCartItems?.cart?.delivery_date || '')
      commit(BasketMutationTypes.SET__AVAILABLE_PAYMENT_METHOD, data?.updateCartItems?.cart?.available_payment_methods || '')
      commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.updateCartItems?.cart?.items)
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update items error:', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_ADDRESS] ({ commit }, dto: ISetShippingAddressRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingAddressResponse, ISetShippingAddressRequest>({
        mutation: fetchShippingAddressMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_addresses: dto.shipping_addresses
        }
      })
      commit(BasketMutationTypes.SET_SHIPPING_ADDRESS, data?.setShippingAddressesOnCart)
    } catch (error) {
      console.error('shipping address error', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_METHODS] ({ commit }, dto: ISetShippingMethodRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingMethodResponse, ISetShippingMethodRequest>({
        mutation: fetchShippingMethodMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_methods: dto.shipping_methods
        }
      })
      const method = data?.setShippingMethodsOnCart?.cart.shipping_addresses as any
      if (typeof method !== 'undefined') {
        commit(BasketMutationTypes.SET_SHIPPING_METHODS, method[0]?.selected_shipping_method)
      }
    } catch (error) {
      console.error('shipping methods error', error)
    }
  },
  async [BasketActionTypes.SET_PAYMENT_METHOD] ({ commit }, dto: ISetPaymentMethodRequest) {
    const { data } = await apolloClient.mutate<ISetPaymentMethodResponse, ISetPaymentMethodRequest>({
      mutation: fetchPaymentMethodMutation,
      variables: {
        cart_id: dto.cart_id,
        payment_method: dto.payment_method
      }
    })
    commit(BasketMutationTypes.PAYMENT_METHOD, data?.setPaymentMethodOnCart)
  },
  async [BasketActionTypes.FETCH_TRANSPORT_TYPE] ({ commit }, dto: ISetTransportTypeRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetTransportTypeResponse, ISetTransportTypeRequest>({
        mutation: fetchTransportTypeMutation,
        variables: {
          transporttype: dto.customertype,
          cart_id: dto.cart_id,
          customertype: dto.customertype,
          sappaymentcode: dto.sappaymentcode,
          sappaymentmethod: dto.sappaymentmethod
        }
      })
      if (data?.setTransportType?.message) {
        commit(BasketMutationTypes.SET_TRANSPORT_TYPE, dto.transporttype)
        localStorage.setItem('shippingMethod', dto.transporttype)

        // @TODO temporarily commented - waiting for BE's decision
        // const defaultNonEmptyTransportType = ['flatrate_flatrate', 'selfpickup_selfpickup']
        // if (defaultNonEmptyTransportType.includes(dto.transporttype)) {
        //   localStorage.setItem('onlyEmpties', JSON.stringify(0))
        // } else {
        //   localStorage.setItem('onlyEmpties', JSON.stringify(1))
        // }

        // commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('transport type error', error)
    }
  },
  async [BasketActionTypes.UPDATE_PRICES] ({ commit }, dto: IUpdatePricesRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const queryVariables = (dto.simulate_type === 'packages')
        ? {
            cart_id: dto.cart_id,
            delivery_date: dto.delivery_date,
            date_popup: dto.date_popup,
            payment_method: dto.payment_method,
            outlet_order_type: dto.outlet_order_type,
            delivery_method: dto.delivery_method,
            payment_method_title: dto.payment_method_title,
            note_for_driver: dto.note_for_driver,
            shipping_type: dto.shipping_type,
            order_reason: dto.order_reason,
            simulate_type: dto.simulate_type,
            ship_to_id: dto.ship_to_id,
            sold_to_id: dto.sold_to_id,
            package_products: dto.package_products
          }
        : {
            cart_id: dto.cart_id,
            delivery_date: dto.delivery_date,
            date_popup: dto.date_popup,
            payment_method: dto.payment_method,
            outlet_order_type: dto.outlet_order_type,
            delivery_method: dto.delivery_method,
            payment_method_title: dto.payment_method_title,
            note_for_driver: dto.note_for_driver,
            shipping_type: dto.shipping_type,
            order_reason: dto.order_reason,
            confirmation_email: dto.confirmation_email
          }
      const { data } = await apolloClient.mutate<IUpdatePricesResponse, IUpdatePricesRequest>({
        mutation: fetchUpdatePricesMutation,
        variables: queryVariables as unknown as IUpdatePricesRequest
      })
      if (data?.updatePrices) {
        commit(BasketMutationTypes.SET_PRICES, data?.updatePrices)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update prices error:', error)
    }
  },
  async [BasketActionTypes.PLACE_ORDER] ({ commit }, dto: IPlaceOrderRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    try {
      const { data } = await apolloClient.mutate<IPlaceOrderResponse, IPlaceOrderRequest>({
        mutation: fetchPlaceOrderMutation,
        variables: {
          cart_id: dto.cart_id
        }
      })
      if (data) {
        commit(BasketMutationTypes.SET_ORDER, data?.placeOrder?.order)
        commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
        localStorage.removeItem('customerOrderNumber')
        localStorage.removeItem('noteForDriver')
      }
      commit(BasketMutationTypes.SET_ERROR, [])
    } catch (error: any) {
      commit(BasketMutationTypes.SET_ERROR, [error?.message])
      commit(BasketMutationTypes.SET_LOADING, false)
      console.error('place order error', error)
    }
  },
  async [BasketActionTypes.SAVE_ORDER_FOR_LATER] ({ commit }, { cartId, customer_order_number }: IAddSaveOrderForLaterRequest) {
    try {
      const { data } = await apolloClient.query<IAddSaveOrderForLaterResponse, IAddSaveOrderForLaterRequest>({
        query: addSaveOrderForLaterQuery,
        variables: {
          cartId,
          customer_order_number
        }
      })
      commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.VALIDATE_PRICES] ({ commit }, dto: IValidatePricesRequest) {
    try {
      const { data } = await apolloClient.query<IValidatePricesResponse, IValidatePricesRequest>({
        query: validateProductPrice,
        variables: {
          productItems: dto.productItems
        }
      })
      commit(BasketMutationTypes.SET_VALIDATION_MESSAGE, [])
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.GET_FIRST_AVAILABLE_DATE] ({ commit }, dto: IGetAvailableDateRequest) {
    await getAvailableDateAPI(dto).then((res) => {
      commit(BasketMutationTypes.SET_FIRST_AVAILABLE_DATE, res.firstAvailableDate)
      commit(BasketMutationTypes.SET_FIRST_AVAILABLE_DATE_FEE, res.firstAvailableDateWithFee)
    })
  },
  async [BasketActionTypes.GET_AVAILABLE_DAYS] ({ commit }, dto: IGetAvailableDateRequest) {
    await getCalendarAvailableDaysAPI(dto).then((res) => {
      commit(BasketMutationTypes.SET_AVAILABLE_DAYS, res)
    })
  },
  async [BasketActionTypes.FETCH_PAYMENT_METHOD] ({ commit }, dto: any) {
    try {
      const data = await fetchPaymentMethod({
        payerid: dto.payerid,
        lang: dto.lang
      })
      if (data?.payers?.length > 0) {
        commit(BasketMutationTypes.PAYMENT_METHOD_CODE, data.payers[0].paymentmethod)
        if (dto.lang === 'en') {
          commit(BasketMutationTypes.PAYMENT_METHOD, data.payers[0].paymentmethodDescriptionEN)
        } else {
          commit(BasketMutationTypes.PAYMENT_METHOD, data.payers[0].paymentmethodDescription)
        }
      }
    } catch (e: unknown) {
      commit(BasketMutationTypes.SET_ERROR, e as IError[])
      throw e
    }
  },
  async [BasketActionTypes.FETCH_ORDER_REASON] ({ commit }, dto: any) {
    try {
      const data = await fetchOrderReason({
        soldToId: dto.soldToId,
        deliveryDate: dto.deliveryDate
      })
      if (data) {
        commit(BasketMutationTypes.ORDER_REASON, data)
      }
    } catch (e: unknown) {
      commit(BasketMutationTypes.SET_ERROR, e as IError[])
      throw e
    }
  },
  async [BasketActionTypes.FETCH_ORDER_TYPE] ({ commit }, dto: any) {
    try {
      const data = await fetchOrderType({
        soldToId: dto.soldToId
      })
      if (data) {
        commit(BasketMutationTypes.ORDER_TYPE, data)
      }
    } catch (e: unknown) {
      commit(BasketMutationTypes.SET_ERROR, e as IError[])
      throw e
    }
  }
}

const fetchPaymentMethod = async (dto: {payerid: string, lang: string}): Promise<any> => {
  const response: AxiosResponse = await generalServiceAPI.get<any>(`payers/${dto.payerid}`)
  return response.data
}

const fetchOrderReason = async (dto: {soldToId: string, deliveryDate: string}): Promise<any> => {
  const response: AxiosResponse = await generalServiceAPI.get<any>('orders/GetOrderReason', { params: dto })
  return response.data
}

const fetchOrderType = async (dto: {soldToId: string}): Promise<any> => {
  const response: AxiosResponse = await generalServiceAPI.get<any>('orders/GetOrderType', { params: dto })
  return response.data
}

const BasketModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default BasketModule
