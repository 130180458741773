import gql from 'graphql-tag'

export const fetchCartQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    note_for_driver
    customer_order_number
    delivery_date
    delivery_method
    outlet_details {
      payer_name
      outlet_name
      street
      city
      country
      province
      postalcode
      sap_id
    }
    payer_details {
      payername
      street
      city
      country
      zipcode
      sap_id
      identification_no
      taxvat
    }
    progress_bar_data {
      iMinimumOrderPallet,
      iMaximumOrderPallet,
      iMinimumOrderWeight,
      iMaximumOrderWeight,
      iMinimumUnit,
      iMaximumUnit,
      iKegFlag,
      iCustomerType,
      iTotalCartWeight,
      iTotalCartPalletQty,
      iTotalQty,
      msg,
      canProceed
    }
    available_payment_methods {
      code
      title
    }
     selected_payment_method {
      code
      title
    }
    id
    email
    total_quantity
    multi_deposit
    ship_to_id
    grand_total_basic_price
    grand_total_basic_price_tax
    grand_total_your_price
    grand_total_your_price_tax
    grand_total_multi_deposit
    bundle_your_price_sum_grand_total
    bundle_your_price_sum_grand_total_tax
    cart_prices {
      grand_total_deposit
      grand_total_deposit_with_vat
      grand_total_price
      grand_total_price_with_vat
      grand_total_price_with_vat_returnable
      grand_total_price_with_vat_returnable_vat
      grand_total_redemption_fee
      returnable_items{
        parent_sku
        sku
        total_units
        total_price
        unit_price
        name
      }
    }
    quantity_totals
    items {
      id
      uid
      prices {
        total_item_discount {
          value
        }
        price {
          value
        }
        basic_price
        basic_price_tax
        your_price
        your_price_tax
        redemption_fee
        multi_deposit_with_vat
        total_basic_price
        total_basic_price_tax
        total_your_price
        total_your_price_tax
        favourites
        multi_deposit
        total_multi_deposit
        row_total {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      product {
        id
        name
        product_relation
        sku
        url_key
        packaging
        package_type
        volume
        material_type
        number_of_items_in_box
        swatch_image
        number_of_items_on_pallet
        image {
          url
        }
        ... on BundleProduct {
          bundle_price_sum
          bundle_price_sum_tax
          your_price_sum
          your_price_sum_tax
        }
        __typename
      }
      ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
              uid
            }
          }
        }
      quantity
      quantity_total
      number_of_packages
      number_of_layer
      number_of_layer_total
      number_of_pallet
      pack_format
    }
    applied_coupons {
      code
    }
    prices {
      discounts {
        amount {
          value
        }
        label
      }
      grand_total {
        value
      }
    }
  }
}
`)
