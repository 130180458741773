export const PORTAL_DICTIONARY = {
  en: {
    createAnOrder: 'CREATE AN ORDER',
    createAnOrderIt: 'Create An Order',
    cart: 'MY CART',
    legalClause: 'Legal clause',
    backUp: 'Back up',
    menu: 'Menu',
    tutorial: 'Tutorial',
    search: 'Search',
    home: 'Homepage',
    profile: 'My Profile',
    contracts: 'Contracts',
    orders: 'Orders',
    history: 'History',
    savedOrders: 'Saved Orders',
    catalog: 'Catalogue',
    products: 'Product Overview',
    favorites: 'My Favourites',
    packages: 'Packages',
    favoritesMenu: 'MY FAVOURITES',
    reporting: 'Reporting',
    myDocuments: 'My documents',
    marketingMaterials: 'Marketing Materials',
    newsEvents: 'News & Events',
    aboutDreherMenu: 'About Dreher Breweries',
    support: 'Support',
    faq: 'FAQ',
    knowledgeBase: 'Knowledge Base',
    supportTickets: 'Other Requests',
    eLearning: 'Academy',
    WHSPortal: 'WHS Portal',
    pubMenu: 'Menu Creator',
    emptiesManagement: 'Empties Management',
    contacts: 'Contacts',
    about: 'ABOUT US',
    privacyPolicy: 'Privacy Policy',
    privacyProtection: 'Privacy Protection',
    cookies: 'Cookies and personal data protection',
    brands: 'BRANDS',
    aboutAsahiFB: 'Asahi',
    productCode: 'Product code',
    removeItem: 'Remove Item',
    createNewUser: 'Create New User',
    trainingLearning: 'Training & Learning',
    Unit: 'Unit',
    actions: {
      edit: 'My Profile',
      logout: 'Logout'
    },
    languages: {
      hu: 'Magyar',
      en: 'English'
    },
    login: 'Login',
    goToCart: 'GO TO CART',
    notificationsEmpty: 'No notifications',
    termsOfUse: 'Terms of use',
    corporateInformation: 'Corporate Information',
    corporateGovernance: 'Corporate Governance',
    enjoyResponsibly: 'Enjoy repsonsibly!',
    ageGateText: 'For adults only, 18 and older!'
  },
  hu: { // TODO translate
    createAnOrder: 'RENDELÉS LEADÁSA',
    administration: 'Administrace',
    cart: 'KOSARAM',
    home: 'Kezdőlap',
    profile: 'Profilom',
    contracts: 'Szerződések',
    orders: 'Rendelések',
    history: 'Korábbi rendelések',
    savedOrders: 'Mentett rendelések',
    catalog: 'Katalógus',
    products: 'Termék áttekintő',
    favorites: 'Kedvencek',
    packages: 'Csomagok',
    favoritesMenu: 'KEDVENCEK',
    backUp: 'Elejére',
    reporting: 'Riportok',
    aboutAsahiFB: 'Asahi',
    myDocuments: 'Dokumentumaim',
    marketingMaterials: 'Marketing anyagok',
    newsEvents: 'Hírek, események',
    aboutDreherMenu: 'Rólunk',
    support: 'Segítség',
    faq: 'GYIK',
    knowledgeBase: 'Tudásbázis',
    supportTickets: 'Egyéb kérések',
    eLearning: 'Akadémia',
    WHSPortal: 'WHS portál',
    pubMenu: 'Menü készítő',
    legalClause: 'Právna doložka',
    menu: 'Menu',
    tutorial: 'Útmutató',
    search: 'Buscar',
    contacts: 'Contacto',
    about: 'RÓLUNK',
    privacyPolicy: 'Política de privacidad',
    privacyProtection: 'Protección de la privacidad',
    cookies: 'Cookies y Protección de Datos Personales',
    brands: 'MÁRKÁK',
    productCode: 'kód produktu',
    createNewUser: 'Vytvoriť nového užívateľa',
    trainingLearning: 'Képzés, tanulás',
    Unit: 'Szállítási egység',
    actions: {
      edit: 'Profilom',
      logout: 'Kijelentkezés'
    },
    languages: {
      hu: 'Magyar',
      en: 'English'
    },
    goToCart: 'KOSÁRHOZ',
    removeItem: 'eliminar producto',
    login: 'Acceso',
    notificationsEmpty: 'Žiadne notifikácie',
    termsOfUse: 'Használati feltételek',
    enjoyResponsibly: 'Fogyaszd felelősséggel',
    ageGateText: 'Csak 18 éven felülieknek!'
  }
}
