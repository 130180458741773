import gql from 'graphql-tag'

export const fetchProductWishlistQuery = gql`
  {
    products(filter: {}, pageSize: 4, currentPage: 1, sort: {}) {
      total_count
      items {
        name
        sku
        product_name_sap
        __typename
        description {
          html
        }
        your_price
        your_price_tax
        redemption_fee
        multi_deposit_z099
        downloads
        favourites
        promo
        weight
        packaging
        product_name_sap
        brand
        alcohol_content
        number_of_items_on_pallet
        number_of_products_on_layer
        number_of_pieces_in_crate
        number_of_pieces_in_carton
        pack_format
        volume
        catalog_product_name
        product_last
        product_new
        product_sale
        swatch_image
        small_image {
          url
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
        }
        thumbnail {
          url
        }
        image {
          url
        }
        media_gallery {
          url
        }
        ... on BundleProduct {
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            options {
              id
              label
              quantity
              price
              position
              product {
                id
                name
                sku
                your_price
                #   stock_status
                #   __typename
              }
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`
