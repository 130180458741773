import {
  IFetchUsersResponse
} from '@/store/modules/users/interfaces/users/api/fetchUsers/IFetchUsersResponse'
import {
  IFetchUsersRequest
} from '@/store/modules/users/interfaces/users/api/fetchUsers/IFetchUsersRequest'
import {
  IFetchSelectedUserRequest
} from '@/store/modules/users/interfaces/selectedUser/api/fetchSelectedUser/IFetchSelectedUserRequest'
import {
  IFetchSelectedUserResponse
} from '@/store/modules/users/interfaces/selectedUser/api/fetchSelectedUser/IFetchSelectedUserResponse'
import { IFetchPayerDetailsResponse } from '@/store/modules/users/interfaces/selectedUser/api/fetchPayer/IFetchPayerDetailsResponse'
import { AxiosResponse } from 'axios'
import { cdcServiceAPI, generalServiceAPI } from '@/store/api-instance'
import { IPayerSoldTo } from '@/store/modules/users/interfaces/selectedUser/api/fetchPayer/IPayerSoldTo'

export const usersAPI = {
  async fetchUsers (dto: IFetchUsersRequest): Promise<IFetchUsersResponse> {
    const response: AxiosResponse = await cdcServiceAPI.get<IFetchUsersResponse>('users/GetAllUsersInfo', { params: dto })
    return response.data
  },
  async fetchSelectedUser (dto: IFetchSelectedUserRequest): Promise<IFetchSelectedUserResponse> {
    const response: AxiosResponse = await cdcServiceAPI.get<IFetchSelectedUserResponse>(`users/GetUserInfoById/${dto.id}`)
    return response.data
  },
  async fetchPayerDetails (dto: IFetchSelectedUserRequest): Promise<IFetchPayerDetailsResponse> {
    const response: AxiosResponse = await generalServiceAPI.get<IFetchPayerDetailsResponse>(`payers/${dto.id}`)
    return response.data
  },

  async fetchPayerSoldTo (dto: IFetchSelectedUserRequest): Promise<IPayerSoldTo> {
    const response: AxiosResponse = await generalServiceAPI.get<IPayerSoldTo>(`soldtos/GetIsLspSoldTo/${dto.id}`)
    return response.data
  }
}
