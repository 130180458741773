export enum BasketMutationTypes {
  'SET_EMPTY_CART' = 'SET_EMPTY_CART',
  'SET_CART_QUERY' = 'SET_CART_QUERY',
  'SET_PRODUCTS' = 'SET_PRODUCTS',
  'SET_TOTAL_PRICE' = 'SET_TOTAL_PRICE',
  'SET_LOADING' = 'SET_LOADING',
  'SET_ERROR' = 'SET_ERROR',
  'SET_BASKET_ID' = 'SET_BASKET_ID',
  'SET_BASKET_ITEMS' = 'SET_BASKET_ITEMS',
  'SET_BASKET_PRODUCTS' = 'SET_BASKET_PRODUCTS',
  'SET_CUSTOMER' = 'SET_CUSTOMER',
  'SET_BILLING_ADDRESS' = 'SET_BILLING_ADDRESS',
  'SET_SHIPPING_ADDRESS' = 'SET_SHIPPING_ADDRESS',
  'SET_SHIPPING_METHODS' = 'SET_SHIPPING_METHODS',
  'PAYMENT_METHOD' = 'PAYMENT_METHOD',
  'SET__AVAILABLE_PAYMENT_METHOD' = 'SET__AVAILABLE_PAYMENT_METHOD',
  'SET_ORDER' = 'SET_ORDER',
  'SET_PROGRESS_BAR' = 'SET_PROGRESS_BAR',
  'SET_DELIVERY_DATE' = 'SET_DELIVERY_DATE',
  'SET_SHIPMENT_ID' = 'SET_SHIPMENT_ID',
  'SET_TRANSPORT_TYPE' = 'SET_TRANSPORT_TYPE',
  'SET_VALIDATION_MESSAGE' = 'SET_VALIDATION_MESSAGE',
  'SET_FIRST_AVAILABLE_DATE' = 'SET_FIRST_AVAILABLE_DATE',
  'SET_PRICES' = 'SET_PRICES',
  'SET_FIRST_AVAILABLE_DATE_FEE' = 'SET_FIRST_AVAILABLE_DATE_FEE',
  'PAYMENT_METHOD_CODE' = 'PAYMENT_METHOD_CODE',
  'ORDER_REASON' = 'ORDER_REASON',
  'ORDER_TYPE' = 'ORDER_TYPE',
  'SET_AVAILABLE_DAYS' = 'SET_AVAILABLE_DAYS',
  'SET_SHIPPING_TYPE' = 'SET_SHIPPING_TYPE'
}
