
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import HelpActionButton from '@/components/HelpActionButton.vue'
import TutorialModal from '@/components/TutorialModal.vue'
import MessageModal from '@/components/common/MessageModal.vue'
import BannersComponent from '@/components/BannersComponent.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { setI18nLanguage } from '@/i18n'
import Error401Page from './pages/Error401Page.vue'
import { constants } from '@/constants'
import BeforeLoginLayout from '@/components/layout/BeforeLoginLayout.vue'
import { LanguagesType } from '@/types'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import AppGlobalModal from '@/components/app/AppGlobalModal.vue'
import ModalComponent from './components/ModalComponent.vue'
import ResizeObserver from '@/components/ResizeObserver.vue'
import { IAuthState } from './store/modules/auth/IAuthState'
import { IPermissionsConfig } from './store/modules/main/intefaces/IConfig'
import { getValueByLabel } from './utils/permissions'
import AppMenuMixin from './mixins/appMenu'
import { PORTAL_DICTIONARY } from '@/const/dictionary'

export default defineComponent({
  name: 'App',

  components: {
    HelpActionButton,
    TutorialModal,
    Error401Page,
    MessageModal,
    BannersComponent,
    UserToolbar,
    BeforeLoginLayout,
    ModalComponent,
    AppGlobalModal,
    ResizeObserver
  },

  mixins: [AppMenuMixin],

  data: () => ({
    customerRoleType: constants.customertype,
    gigICode: '',
    gigIvToken: '',
    isPortalChrome: true,
    theme: constants.application.theme,
    isLoaded: false,
    openModal: false,
    message: '',
    subMessage: '',
    isErrorModal: true,
    copyRightMsg: `© Copyright ASAHI - ${new Date().getFullYear()}`,
    activeLanguage: (localStorage.getItem('lang') || constants.application.defaulLang) as LanguagesType,
    dictionary: PORTAL_DICTIONARY
  }),

  computed: {
    ...mapState('main', ['pageNotFound']),
    ...mapState('basket', ['id', 'items', 'progressBarMsg', 'query']),
    ...mapState('catalog', ['wishlist']),
    ...mapGetters('basket', ['progressBarMessage']),
    ...mapState('main', ['permissions']),

    footerLinksBrands () {
      const footerBrandLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Mobile Brands')
        .map((data) => ({
          label: data.label,
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [
        { title: 'brands' },
        ...footerBrandLinks
      ]
    },
    footerLinksSocial () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Social Media')
        .map((data) => ({
          label: data.label, // Assuming you have a function to get the label from the frontend based on the name
          link: data.url, // Assuming 'url' holds the URL fetched from the backend
          target: '_blank'
        }))

      return aboutUsLinks
    },
    footerLinks () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'About Us')
        .map((data) => ({
          label: data.label, // Use the 'label' field from the backend as the label for about us links
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [
        {
          title: 'about'
        },
        ...aboutUsLinks
      ]
    },
    footerLinksMobile () {
      const mobileBrandsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Mobile Brands')
        .map((data) => ({
          label: data.label, // Use the 'label' field from the backend as the label for mobile brands
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'About Us')
        .map((data) => ({
          label: data.label, // Use the 'label' field from the backend as the label for about us links
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [...mobileBrandsLinks, ...aboutUsLinks]
    },
    canProceed () {
      return this.query?.progress_bar_data?.canProceed === 'yes'
    },
    messageCart () {
      return this.canProceed ? this.$t('The required quantity of goods has been reached') : ''
    },
    linksStore () {
      return this.$store.state.links
    },
    queries () {
      return this.$store.state.catalog.queries
    },

    languages () {
      return ['hu', 'en']
    },

    menuLinks () {
      const menu = [...this.menu]

      const menuWithoutOtherLinks = []
      if (menu[0]?.children?.length > 0) {
        for (let i = 0; i < menu[0]?.children?.length; i++) {
          const element = menu[0].children[i]
          if ('isOther' in element === false) {
            menuWithoutOtherLinks.push(element)
          }
        }
      }

      return menu
    },

    total () {
      return this.$store.state.links.portalHardCodeLink.total
    },

    limit () {
      return this.$store.state.links.portalHardCodeLink.limit
    },

    computedDictionary () {
      const links = this.linksStore?.links?.reduce((acc, value, index) => {
        acc['custom-link' + (index + 1)] = value?.title
        return acc
      }, {} as Record<string, string>)

      const activeLanguage = this.activeLanguage as keyof typeof this.dictionary
      return { ...this.dictionary, [activeLanguage]: { ...this.dictionary[activeLanguage], ...links } }
    },

    user () {
      return this.$store.state.auth.user
    },
    isLoggedIn () {
      return this.user.isLoggedIn // && !!this.user.userValidation
    },
    notAuthorized () {
      return this.$route.meta?.requireAuthentication && !this.isLoggedIn
    },
    showFooter (): boolean {
      return !this.$route.meta?.hideFooter
    },
    cartItems () {
      return this.items?.length
    },
    favoritesItems () {
      return this.wishlist?.items_v2?.items?.length
    },
    helpPagesStore () {
      return this.$store.state.helpPages
    },
    userMenuPermission (): IPermissionsConfig[] {
      return this.permissions || []
    },
    showProfiles (): boolean {
      return getValueByLabel(this.userMenuPermission, 'My Profile') && this.allPermission?.includes('My Profile')
    },
    companyId (): any {
      const { permissionSchema = [] } = this.$store.state.auth.user || {}
      const company = [...permissionSchema].find((permission) => (permission.companyId))
      return company?.companyId
    },
    selectedSoldToId () : any {
      const { permissionSchema = [] } = this.$store.state.auth.user || {}
      let data : any
      let selectedShipto = ''
      if (localStorage.getItem('shipToId') != null && localStorage.getItem('shipToId') !== '') {
        data = permissionSchema.filter((permission) => (permission.accountType === 'Ship-to' &&
        permission.shipToId === localStorage.getItem('shipToId')))
        selectedShipto = data[0]?.shipToId
      } else {
        data = permissionSchema.filter((permission) => (permission.accountType === 'Ship-to'))
        selectedShipto = data[0]?.shipToId
      }
      return selectedShipto
    }
  },

  watch: {
    isLoggedIn: {
      handler (value) {
        if (value === true) {
          this.fetchLinks()
          this.fetchPortalHardCodeLink()
        }
      }
    },
    activeLanguage: {
      handler (value, oldValue) {
        if (oldValue ? value !== oldValue : !!value) {
          this.setMagentoStore(value)
          if (this.isLoggedIn) {
            this.fetchLinks()
            this.fetchPortalHardCodeLink()
            this.getPaymentMethod()
          }
        }
      },
      immediate: true
    },
    'user.country': {
      handler () {
        this.setMagentoStore(this.activeLanguage)
      },
      immediate: true
    },
    $route () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
    },
    progressBarMsg (value, oldValue) {
      if (oldValue !== value) {
        this.$store.commit('basket/SET_PROGRESS_BAR', value)
        return value
      }
    }
  },

  created () {
    this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
    this.isPortalChrome = !this.$route.meta?.noPortalChrome
    const urlParams = new URLSearchParams(window.location.search)
    this.gigICode = urlParams.get('gig_i_code') || ''
    this.gigIvToken = urlParams.get('gig_i_vToken') || ''
  },

  async mounted () {
    this.$nextTick(function () {
      window.gigya.hasSession().then(this.checkSession)
      window.gigya.socialize.addEventHandlers({
        onLogin: async (res: IAuthState) => {
          this.updateUserInfo(res)
          await this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
          localStorage.setItem('shipToId', '')
          await this.$store.dispatch('basket/CREATE_EMPTY_CART', { root: true })
          await this.getFirstAvailableDate()
        }
      })
    })
  },

  methods: {
    async dispatchHelpPages () {
      await this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },
    openTutorialModal () {
      if (
        !(this.helpPagesStore !== undefined && this.helpPagesStore.helpPages && this.helpPagesStore.helpPages.length)
      ) {
        this.dispatchHelpPages()
      }
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).open()
    },
    closeTutorialModal () {
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).close()
    },
    fetchSharePointLink () {
      this.$store.dispatch('whsLink/FETCH_SHAREPOINTLINKS')
        .then(() => {
          const links = this.$store.state.whsLink.Origin
          // Map the array to the desired format
          const sharepointLinks = links.map((item) => ({ Link: item.Link }))

          // Open SharePoint links in a new tab
          this.openLinksInNewTab(sharepointLinks)
        })
        .catch((error) => {
          console.error('Error fetching SharePoint links:', error)
        })
    },
    openLinksInNewTab (links: Array<{ Link: string }>) {
      links.forEach((link) => window.open(link.Link, '_blank'))
    },
    menuNavigate (item: CustomEvent) {
      if (
        item.detail?.name === 'eLearning' ||
        item.detail?.name === 'pubMenu'
      ) {
        this.$gtm.trackEvent({
          event: item.detail?.name + '_click'
        })
        window.gigya.fidm.saml.initSSO({
          spName: item.detail?.name,
          redirectURL: item.detail.href
        })
        return
      } else if (item.detail?.name === 'WHSPortal') {
        // If the item is 'WHSPortal', fetch and open SharePoint links
        this.fetchSharePointLink()
        return
      } else if (/^https?:\/\//i.test(item.detail.href) || /^https?:\/\//i.test(item.detail)) {
        const link = item.detail.href ? item.detail.href : item.detail
        window.open(link, '_blank')
        return
      }

      this.$navigate(item)
    },

    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },

    fetchLinks () {
      this.$store.dispatch(LinksActionType.FETCH_ALL_LINKS)
    },

    /**
     * Method to update logged in user info into vuex store
     * @param res
     */
    async updateUserInfo (res: IAuthState) {
      sessionStorage.setItem('uid', res.UID) // This is for Google Analytics
      this.$store.commit('SET_AUTH_USER', res)
      await this.$store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID).then(() => {
        if (localStorage.getItem('lang') !== null) {
          this.activeLanguage = (localStorage.getItem('lang') ||
            constants.application.defaulLang) as LanguagesType
        }
        if (!this.user.userValidation && !this.$route.meta?.isUserVerification) {
          this.userLogout()
        }
      })
      if (this.user.firstLogin === true && this.user.userValidation) {
        this.openTutorialModal()
      }
    },
    /**
     * Method to initiate logout action
     * on click action of logout button
     */
    userLogout () {
      localStorage.clear()
      sessionStorage.removeItem('uid')
      sessionStorage.removeItem('imp_usr')
      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    /**
     * Method to perform action after user logs out
     * Navigate user to home page
     */
    handleLogout () {
      this.$store.dispatch('logout')
      this.$navigate('/login')
    },
    /**
     * Method to check user session if it exist
     * if it does not exist then redirect user to login page
     * @param sessionExist
     */
    async checkSession (sessionExist: boolean) {
      if (sessionExist) {
        window.gigya.socialize.getUserInfo({ callback: this.updateUserInfo })
      } else {
        if (!this.$route.meta?.isUserVerification && this.$route.meta?.requireAuthentication) {
          this.$navigate('/login')
        }
      }
      this.isLoaded = true
    },
    /**
     * Callback method on click of Profile link under top right user name
     * @param event
     */
    userEdit () {
      this.$navigate('/profile/details')
    },

    setAgeConfirmed (isAgeConfirmed: boolean) {
      if (this.theme === 'dreher') {
        this.isPortalChrome = isAgeConfirmed
      }
    },
    setMagentoStore (language: string) {
      localStorage.setItem('store', `LivePortal_HU_${language}`)
    },

    async removeProductFromCart (productId: string) {
      if (this.$store.state.basket?.id && productId) {
        await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.$store.state.basket.id, cart_item_id: productId }, { root: true })
        await this.$store.dispatch('basket/FETCH_MIN_CART_QUERY_FOR_CATALOG', { cart_id: this.$store.state.basket.id }, { root: true })
      }
    },

    async getPaymentMethod () {
      if (this.companyId) await this.$store.dispatch('basket/FETCH_PAYMENT_METHOD', { payerid: this.companyId, lang: localStorage.getItem('lang') }, { root: true })
    },

    async getFirstAvailableDate () {
      await this.$store.dispatch('basket/GET_FIRST_AVAILABLE_DATE', { soldToId: this.selectedSoldToId })
    }
  }
})
